import React from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthInfo from '../hooks/useAuthInfo';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const {authInfo} = useAuthInfo()
  const navigate = useNavigate();

  // Redirect if not logged in (for non-localhost only)
  React.useEffect(() => {
    // Authinfo was loaded but no client principal
      if (window.location.hostname !== "localhost" && (authInfo && !authInfo?.clientPrincipal?.claims)) {
          navigate("/Welcome");
      }
  }, [authInfo, navigate]);

  // Show loading state until authInfo is set
  if (authInfo === null) {
      return <div>Loading...</div>;
  }

  // Render children if logged in or on localhost
  return <>{children}</>;
};

export default ProtectedRoute;
