import {
  StyleFunctionProps,
  extendTheme,
  createMultiStyleConfigHelpers,
} from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import {
  cardAnatomy,
  drawerAnatomy,
  modalAnatomy,
  menuAnatomy,
  selectAnatomy,
} from '@chakra-ui/anatomy';

const {
  defineMultiStyleConfig: defineCardConfig,
  definePartsStyle: defineCardStyle,
} = createMultiStyleConfigHelpers(cardAnatomy.keys);
const cardTheme = defineCardConfig({
  baseStyle: defineCardStyle({
    container: {
      _dark: {
        backgroundColor: '#40414e',

        _hover: {
          backgroundColor: '#393a46',
        },
      },
    },
  }),
});

const {
  defineMultiStyleConfig: defineDrawerConfig,
  definePartsStyle: defineDrawerStyle,
} = createMultiStyleConfigHelpers(drawerAnatomy.keys);
const drawerTheme = defineDrawerConfig({
  baseStyle: defineDrawerStyle({
    dialog: {
      _dark: {
        backgroundColor: '#40414e',
      },
    },
  }),
});

const {
  defineMultiStyleConfig: defineModalConfig,
  definePartsStyle: defineModalStyle,
} = createMultiStyleConfigHelpers(modalAnatomy.keys);
const modalTheme = defineModalConfig({
  baseStyle: defineModalStyle({
    dialog: {
      _dark: {
        backgroundColor: '#40414e',
      },
    },
  }),
});

const {
  defineMultiStyleConfig: defineMenuConfig,
  definePartsStyle: defineMenuStyle,
} = createMultiStyleConfigHelpers(menuAnatomy.keys);
const menuTheme = defineMenuConfig({
  baseStyle: defineMenuStyle({
    list: {
      _dark: {
        backgroundColor: '#40414e',
      },
    },
    item: {
      _dark: {
        backgroundColor: '#40414e',

        _hover: {
          backgroundColor: '#393a46',
        },
      },
    },
  }),
});

const {
  defineMultiStyleConfig: defineSelectConfig,
  definePartsStyle: defineSelectStyle,
} = createMultiStyleConfigHelpers(selectAnatomy.keys);
const selectTheme = defineSelectConfig({
  baseStyle: defineSelectStyle({
    field: {
      _dark: {
        backgroundColor: '#40414e',
      },
    },
  }),
});

const theme = extendTheme({
  config: {
    useSystemColorMode: false,
    initialColorMode: 'dark',
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        bg: mode('white', '#343541')(props),
      },
    }),
  },
  components: {
    Card: cardTheme,
    Drawer: drawerTheme,
    Modal: modalTheme,
    Menu: menuTheme,
    Select: selectTheme,
  },
});

export default theme;
