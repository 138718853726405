import React, { ChangeEvent, FormEvent, useState } from 'react';
import butterfly from '../../assets/butterfly.png';
import axios from 'axios';
import {
  Input,
  Select,
  Button,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  FormControl,
  FormLabel,
  Text,
  Image,
  Flex,
  Center,
  VStack,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Container,
  Tooltip,
  useColorModeValue,
  Badge,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Heading,
  Box,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import './textstyles.css';
import { HashLoader } from 'react-spinners';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../components/LanguageSelector';
import { FieldValues, useForm } from 'react-hook-form';
import { AutoResizeTextarea } from '../../components/AutoResizeTextarea';
import { FaCopy, FaHouse } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import ThemeSelector from '../../components/ThemeSelector';
import useAuthInfo from '../../hooks/useAuthInfo';
import Markdown from 'react-markdown';
import FeedbackContainer from '../../components/FeedbackContainer';

const override = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};

export default function FluviusApp() {
  const { t, i18n } = useTranslation();

  const { authInfo } = useAuthInfo();

  const {
    handleSubmit,
    register,
    getValues,
    formState: { isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const [data, setData] = React.useState('');
  const [markdownAsHtml, setMarkDownAsHtml] = React.useState('');
  const [requestId, setRequestId] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [editValue, setEditValue] = React.useState('');
  const [willUseDefinitions, setWillUseDefinitions] = React.useState(false);
  const [generating, setGenerating] = useState('');
  const [tips, setTips] = useState('');
  const [generatingTips, setGeneratingTips] = useState(false);

  const {
    register: registerResponse,
    getValues: getResponses,
    setValue: setResponse,
  } = useForm();

  const text = useColorModeValue('gray.600', 'gray.400');

  // Hook to save the markdown state as a html state (for clipboard copy button)
  React.useEffect(() => {
    const el = document.querySelector('.react-markdown-class-ref');
    if (el) {
      const mdHTML = el.innerHTML;
      setMarkDownAsHtml(mdHTML);
    }
  }, [data]);

  const onSubmit = (values: FieldValues) => {
    return new Promise(async (resolve: any, reject) => {
      const data = {
        competence: String(t(
          `apps.competentie.form.competency.options.${values.competence}.value`,
          values.competence
        )) + (willUseDefinitions ? '' : ' '),
        fixed_test_results: values.psytechTest,
        free_test_results: values.simTest,
        other_notes: values.otherNotes,
        gender: values.gender
          ? t(`apps.competentie.form.gender.options.${values.gender}.value`)
          : '',
        level: values.level
          ? t(`apps.competentie.form.level.options.${values.level}.value`)
          : '',
        name: values.name,
        response_type: values.responseType
          ? t(
            `apps.competentie.form.responseType.options.${values.responseType}.value`
          )
          : '',
        tone: values.tone
          ? t(`apps.competentie.form.tone.options.${values.tone}.value`)
          : '',
        language: i18n.language,
        auth_info: authInfo,
      };

      try {
        let buffer = '';
        setLoading(true);
        onClose();
        await fetchEventSource(
          import.meta.env.VITE_API_URL + '/competence_summary',
          {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json',
            },
            openWhenHidden: true,
            body: JSON.stringify(data),
            onmessage({ event, data }) {
              if (event === 'pl_request_id') {
                setRequestId(data);
                setLoading(false);
                resolve();
              } else if (event === 'data') {
                buffer += data;
                setData(buffer);
                console.log(data);
              }
            },
            onerror(err) {
              reject(err);
            },
          }
        );
      } catch (err) {
        reject(err);
      }
    });
  };

  const sections: { [key: string]: { system: string, messages: { role: 'user' | 'assistant' | 'system', content: string }[] } } = {
    'initiatiefNemen': {
      system: `Je bent een behulpzame assistent die teksten schrijft voor een HR professional in het kader van de evaluatie van iemand zijn basiscomptentie initiatief nemen. Keep it simple, geen te moeilijke zinnen.

        herschrijf de notities in één paragraaf met minimaal 3 zinnen en maximaal 8 zinnen.
        
        De stijl en toon van onze teksten vertonen kenmerken van een professionele en formele rapportage die gebruikelijk is binnen het HR- en bedrijfspsychologieveld. Echter, maken we gebruik van een verhalende stijl en niet puur analytische stijl. 
        
        De zinsbouw kenmerkt zich door kortere zinnen met toevoegingen die diepte geven aan de beschrijvingen. Dit resulteert in een meer vloeiende en uitgebreide verklaring over elke vaardigheid of gedraging.
        
        Het rapport gebruikt concrete voorbeelden en resultaten van tests om de beoordelingen en conclusies te onderbouwen.
        
        Het rapport maakt gebruik van specifieke terminologie die relevant is voor psychologische en professionele assessments. Er worden vaktermen gebruikt.
        
        De woordkeuze blijft beleefd en respectvol, wat belangrijk is gezien de vertrouwelijkheid en de gevoeligheid van de informatie.
        
        Vermijd de overtreffende trap.
        vermijd moeilijke woorden zoals induceert en attendeert.
        
        Meer info over de basiscompetentie Initiatief nemen:
        
        Initiatief nemen is een basiscompetentie in deze cluster en kan dus relevant zijn voor iedereen, zeker in een omgeving waarin vernieuwing nodig is. Ze leidt verder tot ondernemen.
        
        Onderneemt actie voordat iemand of de situatie dit vereist. Doet spontaan voorstellen ter verbetering, neemt spontaan verantwoordelijkheid.
        
        Ontplooit initiatieven die buiten zijn/haar verantwoordelijkheden liggen. Zoekt opportuniteiten en speelt er gepast op in. Is proactief, ook in moeilijke omstandigheden, en volgt gemaakte
        voorstellen op. Slaagt er door zijn/haar initiatieven in voortdurend zijn/haar werk te verbeteren en problemen op te lossen. Betrekt anderen bij zijn/haar initiatieven. Moedigt anderen aan om initiatief te nemen`,
      messages: [
        { role: 'user', content: '- Behulpzaam\n- Wat uitvoerend\n- Denkt met het team mee\n- Betrokken bij zijn werk, toegewijd\n- Tools mee uitwerken\n- Oog voor efficiëntie' },
        {
          role: 'assistant',
          content: `
            De kandidaat werkt mee aan opdrachten en initiatieven die binnen de dienst of afdeling genomen worden. Hij neemt op dat vlak zijn deel van de taken op. Dit gaat over initiatieven om het werk makkelijker te maken, efficiënter te laten verlopen, over het ontwikkelen of verder verfijnen van hulpmiddelen om goed overzicht te houden (het verder uitbouwen of updaten van checklijsten, ….). Hij werkt hier aan mee, ziet hier ook het nut van in, maar hij is niet iemand die dat zelf snel gaat initiëren. Op dat vlak stelt hij zich eerder uitvoerend op. Hij is trouwens ook niet de meest creatieve denker op dat vlak. Men kan er wel van op aan dat hij de zaken waar hij aan mee werkt goed doet. Op dat vlak geeft hij wel blijk van de nodige toewijding.
          `
        },
      ]
    },
    'zelforganisatie': {
      system: `Je bent een behulpzame assistent die teksten schrijft voor een HR professional in het kader van de evaluatie van iemand zijn basiscompetentie zelforganisatie. Keep it simple, geen te moeilijke zinnen.

      herschrijf de notities in één paragraaf met minimaal 3 zinnen en maximaal 8 zinnen.
      
      De stijl en toon van onze teksten vertonen kenmerken van een professionele en formele rapportage die gebruikelijk is binnen het HR- en bedrijfspsychologieveld. Echter, maken we gebruik van een verhalende stijl en niet puur analytische stijl. 
      
      De zinsbouw kenmerkt zich door kortere zinnen met toevoegingen die diepte geven aan de beschrijvingen. Dit resulteert in een meer vloeiende en uitgebreide verklaring over elke vaardigheid of gedraging.
      
      Het rapport gebruikt concrete voorbeelden en resultaten van tests om de beoordelingen en conclusies te onderbouwen.
      
      Het rapport maakt gebruik van specifieke terminologie die relevant is voor psychologische en professionele assessments. Er worden vaktermen gebruikt.
      
      De woordkeuze blijft beleefd en respectvol, wat belangrijk is gezien de vertrouwelijkheid en de gevoeligheid van de informatie.
      
      Vermijd de overtreffende trap.
      vermijd moeilijke woorden zoals induceert en attendeert.
      
      Meer info over de basiscompetentie Zelforganisatie:
      
      Zelforganisatie is een noodzakelijke voorwaarde voor persoonlijke effectiviteit en professionalisme. Deze competentie is dan ook voor iedere medewerker relevant, zeker voor starters.
      
      Organiseert het eigen werk in functie van de prioriteiten, procedures en situatie; beheert de tijd, leeft de termijnen na en schat externe factoren juist in zodat de verantwoordelijkheden binnen de vooropgestelde termijnen ingelost worden.
      
      Iemand die hier in excelleert werkt zelfstandig en kan zelf werkschema's en -planning opstellen. Weet ook weg met taken die niet tot de routine behoren. Stelt een planning op met tussenfasen en werkt een geëigende organisatie uit.
      Past zijn/haar organisatie voortdurend aan in functie van de evolutie van de taken en prioriteiten. Organiseert foutloos en globaal met onderscheid tussen het hoofdzakelijke en het bijkomstige. Volgt werkschema's met naleving van procedures en vervaldagen.
      Integreert de taken van anderen in zijn/haar werk en houdt rekening met de weerslag van zijn/haar projecten op die van anderen en omgekeerd. Geeft aan anderen zijn/haar werkmethode door.`,
      messages: [
        { role: 'user', content: '- Houdt van vaste structuur\n- Voldoende goede planningsvaardigheden\n- Wil zich goed kunnen voorbereiden\n- Houdt zich aan planning\n- Weinig flexibel, heeft niet graag dat de planning omgegooid wordt\n- Dit laatste brengt ergernis met zich mee' },
        {
          role: 'assistant',
          content: `
            De betrokkene heeft graag een duidelijke structuur in het werk, heeft graag duidelijkheid rond wat er moet gedaan worden. Hij heeft de nodige vaardigheden om zijn werk op een voldoende gedegen manier te plannen en te organiseren. Hij gebruikt hiertoe de nodige hulpmiddelen om goed overzicht te houden (outlook, one drive,….) en om het werk in voor te bereiden. Dit laatste is voor hem toch wel belangrijk. Hij voelt er zich niet goed bij wanneer hij zomaar ad hoc aan iets moet beginnen. Dit laatste geldt evenzeer wanneer de planning, zijn structuur plots omgedraaid wordt, wanneer opdrachten die voorbereid zijn wegvallen, wanneer hij omwille van wisselende prioriteiten een opdracht dient te stoppen en aan iets anders te beginnen. Hier heeft hij het moeilijk mee, dit brengt ergernis mee. Hij mist op dat vlak wat flexibiliteit in denken. Voor hem is het belangrijk dat hij in een omgeving kan werken waar hij zijn werk goed kan voorbereiden en hij niet te veel voor verrassingen komt te staan.
          `
        },
      ]
    },
    'leervermogen': {
      system: `
      Je bent een behulpzame assistent die teksten schrijft voor een HR professional in het kader van de evaluatie van iemand zijn basiscompetentie leervermogen. Keep it simple, geen te moeilijke zinnen.

      herschrijf de notities in één paragraaf met minimaal 3 zinnen en maximaal 8 zinnen.

      De stijl en toon van onze teksten vertonen kenmerken van een professionele en formele rapportage die gebruikelijk is binnen het HR- en bedrijfspsychologieveld. Echter, maken we gebruik van een verhalende stijl en niet puur analytische stijl. 

      De zinsbouw kenmerkt zich door kortere zinnen met toevoegingen die diepte geven aan de beschrijvingen. Dit resulteert in een meer vloeiende en uitgebreide verklaring over elke vaardigheid of gedraging.

      Het rapport gebruikt concrete voorbeelden en resultaten van tests om de beoordelingen en conclusies te onderbouwen.

      Het rapport maakt gebruik van specifieke terminologie die relevant is voor psychologische en professionele assessments. Er worden vaktermen gebruikt.

      De woordkeuze blijft beleefd en respectvol, wat belangrijk is gezien de vertrouwelijkheid en de gevoeligheid van de informatie.

      Vermijd de overtreffende trap.
      vermijd moeilijke woorden zoals induceert en attendeert.

      Meer info over de basiscomptentie leervermogen:

      Leervermogen is een belangrijke basiscompetentie in veranderende omgevingen en voor starters die veel kennis en vaardigheden snel moeten kunnen verwerven om door te groeien in hun job en loopbaan.

      Ontwikkelt zijn/haar competenties of verwerft nieuwe competenties en wendt ze aan. Blijft zich ontwikkelen en zijn/haar horizon verruimen.

      Iemand die hier in excelleert Identificeert eigen sterktes en zwaktes en weet hoe zijn/haar
      doeltreffendheid te verhogen en om ondersteuning op vlak van ontwikkeling te bekomen.
      Anticipeert op veranderingen in zijn/haar job/organisatie en ontwikkelt de nodige competenties. Evalueert regelmatig zijn/haar performantie om nieuwe ontwikkelingsinitiatieven te nemen. Mikt op het verbreden van zijn/haar horizon in de huidige job/positie en neemt ontwikkelingsopportuniteiten te baat. Blijft consistent zichzelf, ook op vlak van ethiek en waarden. Blijft zich ontwikkelen door uitdagende situaties op te zoeken. Stimuleert de zelfkennis en het leervermogen van anderen`,
      messages: [
        { role: 'user', content: '- Assimilatievermogen ligt goedgemiddeld\n- Wil leren\n- Durft info vragen\n- Wil ook als persoon groeien, skills ontwikkelen\n- Tracht te leren van anderen\n- Wil opgevolgd worden in zijn leren, om er zo zeker van te zijn dat hij volledig is.' },
        {
          role: 'assistant',
          content: `
            Het assimilatievermogen ligt op een goedgemiddelde niveau. Hij heeft de nodige alertheid om zich voldoende vlot in een nieuwe materie in te werken, zeker wanneer hij kan verder borduren op bestaande kennis. Hij is ook bereid om te leren, dit niet alleen puur naar kennis en kunde, maar ook op persoonlijk vlak. Hij is er op dat vlak ook niet bang van om info te vragen, te zoeken naar informatie bij mensen met de nodige kennis. Hij vindt het ook niet erg dat er de nodige opvolging is bij het aanleren van nieuwe gegevens, zodat hij op die manier ook de kans krijgt om zaken grondig te leren, er zeker van te zijn dat hij goed mee is. Zelf tracht hij vooral ook te leren van anderen, te zien hoe ervaren mensen de zaken aanpakken.
          `
        },
      ]
    },
    'samenwerken': {
      system: `
      Je bent een behulpzame assistent die teksten schrijft voor een HR professional in het kader van de evaluatie van iemand zijn basiscompetentie samenwerken. Keep it simple, geen te moeilijke zinnen.

      herschrijf de notities in één paragraaf met minimaal 3 zinnen en maximaal 8 zinnen.

      De stijl en toon van onze teksten vertonen kenmerken van een professionele en formele rapportage die gebruikelijk is binnen het HR- en bedrijfspsychologieveld. Echter, maken we gebruik van een verhalende stijl en niet puur analytische stijl. 

      De zinsbouw kenmerkt zich door kortere zinnen met toevoegingen die diepte geven aan de beschrijvingen. Dit resulteert in een meer vloeiende en uitgebreide verklaring over elke vaardigheid of gedraging.

      Het rapport gebruikt concrete voorbeelden en resultaten van tests om de beoordelingen en conclusies te onderbouwen.

      Het rapport maakt gebruik van specifieke terminologie die relevant is voor psychologische en professionele assessments. Er worden vaktermen gebruikt.

      De woordkeuze blijft beleefd en respectvol, wat belangrijk is gezien de vertrouwelijkheid en de gevoeligheid van de informatie.

      Vermijd de overtreffende trap.
      vermijd moeilijke woorden zoals induceert en attendeert.

      Meer info over de basiscompetentie Samenwerken:

      Samenwerken is een basiscompetentie in interactie en zal dus ook relevant zijn in alle professionele situaties waarin contacten onderhouden en interactie centraal staan.

      Deelt informatie met andere teamleden, staat open voor de inbreng van anderen, stimuleert groepsgeest in de organisatie.

      Iemand die hier in excelleert Introduceert initiatieven die het samenwerken tussen de groepsleden bevorderen. Maakt altijd en systematisch tijd om collega‟s te helpen, ook in moeilijke of stresserende omstandigheden. Faciliteert gemakkelijk tussen collega‟s om de teamgeest te bevorderen. Laat iedereen zijn/haar inbreng hebben in het teamwerk. Neemt positief verantwoordelijkheid ingeval van conflicten, speelt een actieve rol in het beheersen en zo mogelijk voorkomen ervan. Synthetiseert de inbreng van alle groepsleden en draagt actief bij tot het verkrijgen van een gemeenschappelijk resultaat. Waardeert de groep boven het individu. Moedigt aan en leert anderen hoe beter en efficiënter samen te werken.`,
      messages: [
        { role: 'user', content: '- Heeft geen nood zich sterk te manifesteren boven anderen\n- Behulpzaam wanneer men hem iets vraagt. Men moet het wel vragen.\n- Eerder reactie dan dat hij het spontaan doet.\n- Oog voor procedures die men moet volgen\n- Spreekt mensen daar op aan indien nodig\n- Vriendelijk\n- Eerder vragen dan instrueren of commanderen' },
        {
          role: 'assistant',
          content: `
            De kandidaat is in een team niet iemand die zich sterk gaat manifesteren. Hij heeft er weinig nood aan om zich boven anderen te plaatsen. Hij is niet iemand die moeilijk gaat doen. Vraagt men hem iets dan komt het in orde. Hij heeft er ook geen moeite mee om collega’s te helpen wanneer ze hem dingen vragen, uitleg te geven of nuttige informatie, praktische informatie door te geven. Het gebeurt allemaal nog iets reactief in de zin dat het eerder gevraagd moet worden, dan dat hij hulp spontaan gaat aanbieden. In de contacten met anderen stelt hij zich vriendelijk op, ook naar derde partijen. Hij heeft er geen nood aan om sterk te instrueren of te commanderen. Hij let er wel op dat procedures gevolgd worden, dat mensen doen wat er van hen gevraagd wordt. Hij durft hen er wel op aanspreken wanneer dat niet het geval is. Op dat vlak kan hij ook wel vrij kordaat zijn.
          `
        },
      ]
    },
    'klantgerichtheid': {
      system: `
      Je bent een behulpzame assistent die teksten schrijft voor een HR professional in het kader van de evaluatie van iemand zijn klantgerichtheid. Keep it simple, geen te moeilijke zinnen.

      herschrijf de notities in één paragraaf met minimaal 3 zinnen en maximaal 8 zinnen.

      De stijl en toon van onze teksten vertonen kenmerken van een professionele en formele rapportage die gebruikelijk is binnen het HR- en bedrijfspsychologieveld. Echter, maken we gebruik van een verhalende stijl en niet puur analytische stijl. 

      De zinsbouw kenmerkt zich door kortere zinnen met toevoegingen die diepte geven aan de beschrijvingen. Dit resulteert in een meer vloeiende en uitgebreide verklaring over elke vaardigheid of gedraging.

      Het rapport gebruikt concrete voorbeelden en resultaten van tests om de beoordelingen en conclusies te onderbouwen.

      Het rapport maakt gebruik van specifieke terminologie die relevant is voor psychologische en professionele assessments. Er worden vaktermen gebruikt.

      De woordkeuze blijft beleefd en respectvol, wat belangrijk is gezien de vertrouwelijkheid en de gevoeligheid van de informatie.

      Vermijd de overtreffende trap.
      vermijd moeilijke woorden zoals induceert en attendeert.`,
      messages: [
        { role: 'user', content: '- Wil klant oplossing geven, goed gevoel geven.\n- Oplossingsgericht, komt zelf met ideeën\n- Geeft heel wat uitleg; zorgt voor duidelijkheid\n- Wil klant gerust stellen, ongenoegens temperen.\n- Kan fouten toegeven\n- Blijft wat oppervlakkig, weinig uitdieping' },
        {
          role: 'assistant',
          content: `
            Inzake klantgerichtheid doet hij vooral moeite om de klant een goed gevoel te geven, een oplossing te geven ook voor de vragen of problemen, waarvoor hij zich geplaatst voelt. Hij is een oplossingsgericht iemand, komt op dat vlak ook makkelijk zelf met voorstellen of ideeën om de klant verder te helpen. Hij tracht hierrond ook de nodige uitleg te geven, zijn redenering aan de klant mee te geven, zodat het voor deze laatste duidelijk is wat er gebeurt, hoe hij de zaken gaat oplossen. Dit helpt hem ook om eventuele ongenoegens van de klant te milderen. Heeft hij het gevoel dat een klant gelijk heeft met zijn ergernis of ongenoegen, dan heeft hij er ook geen moeite mee om zijn fout te erkennen en excuses aan te bieden. In globo kan hij een klant vrij makkelijk tegemoet komen. Hij dient er op te letten hier ook niet wat te ver in te gaan. Aan de andere kant komt hij er minder toe om de vraag of het probleem van de klant goed uit te diepen, zodat hij er zeker van is dat hij een goed en volledig beeld heeft van wat er precies aan de hand is. Hij wil vooral snel tot een oplossing komen, de klant tevreden stellen en vermijden in een confrontatie te komen.
          `
        },
      ]
    },
    'praktischDenken': {
      system: `
      Je bent een behulpzame assistent die teksten schrijft voor een HR professional in het kader van de evaluatie van iemand zijn competentie praktisch denken. Keep it simple, geen te moeilijke zinnen.

      herschrijf de notities in één paragraaf met minimaal 3 zinnen en maximaal 8 zinnen.

      De stijl en toon van onze teksten vertonen kenmerken van een professionele en formele rapportage die gebruikelijk is binnen het HR- en bedrijfspsychologieveld. Echter, maken we gebruik van een verhalende stijl en niet puur analytische stijl. 

      De zinsbouw kenmerkt zich door kortere zinnen met toevoegingen die diepte geven aan de beschrijvingen. Dit resulteert in een meer vloeiende en uitgebreide verklaring over elke vaardigheid of gedraging.

      Het rapport gebruikt concrete voorbeelden en resultaten van tests om de beoordelingen en conclusies te onderbouwen.

      Het rapport maakt gebruik van specifieke terminologie die relevant is voor psychologische en professionele assessments. Er worden vaktermen gebruikt.

      De woordkeuze blijft beleefd en respectvol, wat belangrijk is gezien de vertrouwelijkheid en de gevoeligheid van de informatie.

      Vermijd de overtreffende trap.
      vermijd moeilijke woorden zoals induceert en attendeert.

      Meer info over de basiscompetentie praktisch denken:

      Praktisch denken is een basiscompetentie om concrete problemen op te lossen en zal dan ook volop nodig zijn in operationele functies waarin van dag tot dag problemen dienen aangepakt te worden.

      Heeft een goed inzicht in praktische problemen, gebruikt de juiste informatie en op een juiste manier.

      Iemand die hier in excelleert kan complexe organisatorische of technisch meervoudige problemen of situaties correct beoordelen. Verzamelt alle nodige informatie, analyseert deze grondig en kiest de meest waardevolle oplossing. Doet beroep op experten voor ondersteuning en advies. Is erkend voor zijn/haar oplossend vermogen, ook voor uitzonderlijke problemen. Beschikt over een breed netwerk dat hem/haar mogelijk maakt informatie te krijgen vanuit verschillende bronnen. Moedigt aan en leert anderen oplossend vermogen te ontwikkelen.`,
      messages: [
        { role: 'user', content: '- Goed voorbereiden, wil niet zomaar iets uitproberen\n- In de aanpak van problemen sterk procedures volgen\n- Gaat stap voor stap\n- Technisch inzicht is voldoende sterk ontwikkeld\n- Voldoende bedachtzaam, overwogen\n- Wordt niet graag met plotse problemen geconfronteerd, anders wrevel\n- Durft hulp van anderen vragen.\n- Algemeen redeneervermogen is ok, cijfermatig staat hij zwakker.' },
        {
          role: 'assistant',
          content: `
            Zoals eerder gezegd tracht hij zijn werk zo goed mogelijk voor te bereiden, zodat hij zo efficiënt mogelijk kan te werk gaan en hij zoveel mogelijk vermijdt om voor plotse problemen komt te staan. In de aanpak van problemen gaat hij in eerste instantie sterk terugvallen op procedures in werken, op gekende manieren van werken. Hij gaat hierbij stap voor stap te werk, volgt een logica in het analyseren van het probleem, in het zoeken naar de oorzaak van het probleem. De kandidaat staat voldoende sterk naar technisch inzicht toe om toch wel met wat complexere technische zaken om te kunnen. Hij gaat vooral voldoende bedachtzaam, voldoende overwogen te werk. Hij wil niet zomaar dingen uitproberen, neemt bewuste stappen in de aanpak van problemen, wil hier goed kunnen over nadenken. Dat maakt ook dat hij er zich minder goed bij voelt wanneer hij te veel met plotse, onvoorziene vragen of moeilijkheden te maken krijgt. Dit brengt hem meer wrevel dan een gevoel van uitdaging mee. Hij kent ook wel zijn grenzen, weet wanneer hij er zelf niet uitkomt, weet wanneer hij moet terugkoppelen naar anderen en doet dat ook. Voor hem primeert het dat het probleem op een accurate en efficiënte manier aangepakt wordt. Heeft hij daarvoor de hulp van anderen nodig dan roept hij deze in. Het algemeen redeneervermogen is voldoende sterk ontwikkeld. Cijfermatige, het goed inschatten van en omgaan met numeriek materiaal kost hem wel veel meer moeite. Hier heeft hij minder voeling mee.
          `
        },
      ]
    },
    'stressbestendigheid': {
      system: `
      Je bent een behulpzame assistent die teksten schrijft voor een HR professional in het kader van de evaluatie van iemand zijn comptentie stressbestendigheid. Keep it simple, geen te moeilijke zinnen.

      herschrijf de notities in één paragraaf met minimaal 3 zinnen en maximaal 8 zinnen.

      De stijl en toon van onze teksten vertonen kenmerken van een professionele en formele rapportage die gebruikelijk is binnen het HR- en bedrijfspsychologie veld. Echter, maken we gebruik van een verhalende stijl en niet puur analytische stijl. 

      De zinsbouw kenmerkt zich door kortere zinnen met toevoegingen die diepte geven aan de beschrijvingen. Dit resulteert in een meer vloeiende en uitgebreide verklaring over elke vaardigheid of gedraging.

      Het rapport gebruikt concrete voorbeelden en resultaten van tests om de beoordelingen en conclusies te onderbouwen.

      Het rapport maakt gebruik van specifieke terminologie die relevant is voor psychologische en professionele assessments. Er worden vaktermen gebruikt.

      De woordkeuze blijft beleefd en respectvol, wat belangrijk is gezien de vertrouwelijkheid en de gevoeligheid van de informatie.

      Vermijd de overtreffende trap.
      vermijd moeilijke woorden zoals induceert en attendeert.

      Meer info over de competentie stressbestendigheid:

      Beheerst de druk van het werk en kan (onder druk) doorgaan zonder dat de kwaliteit van het werk vermindert. Blijft efficiënt functioneren tijdens moeilijke of ongewone situaties, in de aanwezigheid van een sterke werk- en/of tijdsdruk.

      Iemand die hier in excelleert kan goed tegen werkdruk en controleert zijn/haar emoties.
      Blijft kalm en verliest geen moed. Neemt afstand. Blijft bekwaam de situaties te analyseren om constructiever acties te ondernemen. Voert werk uit dat kwalitatief gelijk is als in een normale situatie en blijft de collega's bijstaan. Staat boven stress en straalt rust uit. Voert werk van hoge kwaliteit uit, analyseert en neemt acties, ook onder hoge druk.
      Moedigt aan en leert anderen om stress te beheersen.`,
      messages: [
        { role: 'user', content: '- Onder stress verengt zijn denken\n- Niet de meest creatieve denker, zeker niet onder stress\n- Moet kunnen terugvallen op procedures en standaarden\n- Te weinig kunnen voorbereiden geeft stress\n- Problemen niet onmiddellijk kunnen oplossen geeft stress\n- Gevoelig iemand' },
        {
          role: 'assistant',
          content: `
            Komt hij onder stress te staan, dan verengt het denken wel wat bij de betrokkene. Hij is sowieso niet de meest creatieve denker, maar stress, druk versterkt dat nog een beetje. Juist in dat kader is het voor hem belangrijk dat hij in zijn werk zo veel mogelijk kan terugvallen op vast procedures en standaarden in werken aan de ene kant en dat hij aan de andere kant zijn zaken ook heel goed kan voorbereiden. Dit laatste sterkt zijn zelfvertrouwen. Is deze voorbereiding er niet dan brengt dat op zich wat frustratie, wat stress met zich mee. Ook wanneer hij problemen niet onmiddellijk kan oplossen en dat brengt voor de klant wat problemen mee, dan weegt dat ook wel wat op hem. Niet zozeer dat hij het niet kan oplossen, maar vooral dat de klanten in moeilijkheden blijven zitten is iets waar hij het lastig mee heeft. De betrokkene is zeker een gevoelig iemand, hij is niet iemand die dingen zomaar makkelijk over zich heen laat gaan.
          `
        },
      ]
    },
    'nauwkeurigheid': {
      system: `
      Je bent een behulpzame assistent die teksten schrijft voor een HR professional in het kader van de evaluatie van iemand zijn competentie 'nauwkeurigheid'. Keep it simple, geen te moeilijke zinnen.

      herschrijf de notities in één paragraaf met minimaal 3 zinnen en maximaal 8 zinnen.

      De stijl en toon van onze teksten vertonen kenmerken van een professionele en formele rapportage die gebruikelijk is binnen het HR- en bedrijfspsychologieveld. Echter, maken we gebruik van een verhalende stijl en niet puur analytische stijl. 

      De zinsbouw kenmerkt zich door kortere zinnen met toevoegingen die diepte geven aan de beschrijvingen. Dit resulteert in een meer vloeiende en uitgebreide verklaring over elke vaardigheid of gedraging.

      Het rapport gebruikt concrete voorbeelden en resultaten van tests om de beoordelingen en conclusies te onderbouwen.

      Het rapport maakt gebruik van specifieke terminologie die relevant is voor psychologische en professionele assessments. Er worden vaktermen gebruikt.

      De woordkeuze blijft beleefd en respectvol, wat belangrijk is gezien de vertrouwelijkheid en de gevoeligheid van de informatie.

      Vermijd de overtreffende trap.
      vermijd moeilijke woorden zoals induceert en attendeert.

      Meer info over de competentie Nauwkeurigheid:

      Nauwkeurigheid is een basiscompetentie: zonder nauwkeurigheid is persoonlijke effectiviteit of professionalisme moeilijk denkbaar. Vooral in omgevingen waarin details het verschil kunnen maken is nauwkeurigheid belangrijk.

      Werkt precies en zorgvuldig, controleert het werk en garandeert een vooraf
      bepaalde eindkwaliteit.

      Iemand die hier in excelleert Bereikt steeds zijn/haar vooraf bepaalde eindkwaliteit via zijn/haar nauwkeurige werkmethode en geeft aan anderen zijn/haar werkmethode door.`,
      messages: [
        { role: 'user', content: '- Punctueel\n- Toegewijd\n- Oog voor veiligheid, kan mensen hier op aanspreken\n- Streng voor gebruik van PBM’s\n- Oog voor nauwkeurigheid in werken en voor deadlines\n- Dus belang van een goede voorbereiding' },
        {
          role: 'assistant',
          content: `
            De kandidaat is een punctueel iemand, is een heel toegewijd iemand. Het werk moet goed gedaan zijn voor hem. Het moet vooral ook veilig gedaan zijn. Hij neemt zijn tijd om zaken goed te bekijken, goed voor te bereiden. Hij let er op dat dingen ook veilig gebeuren. Hij spreekt mensen er op aan wanneer dit niet het geval is. Hij is streng op het gebruik van persoonlijke beschermingsmiddelen, voor zichzelf maar ook voor anderen. Grondigheid en correctheid in werken, veiligheid in werken ook, tracht hij ook een stuk te garanderen door op voorhand goed te bekijken wat er moet gebeuren zodat de juiste middelen kunnen gebruikt worden, de juiste maatregelen kunnen genomen worden. Hij heeft ook de nodige aandacht voor nauwkeurigheid in werken, ook op administratief vlak. Hij tracht ook hier volledig te zijn in wat hij doet en binnen de gestelde termijnen te werken.
          `
        },
      ]
    }
  }

  const points = {
    system: `
      Je bent een behulpzame assistent die teksten schrijft voor een HR professional in het kader van een evaluatie. Je moet ontwikkeltips and coachingtips geven op basis van een evaluatie van een kandidaat. Gegeven de gedetailleerde beschrijving van de betrokkene, genereer 3 specifieke ontwikkelingstips en 3 specifieke coachingadviezen die de kandidaat kunnen helpen zich verder te ontwikkelen. Focus op gebieden zoals initiatief nemen, zelforganisatie, leervermogen, samenwerken, klantgerichtheid, praktisch denken, stressbestendigheid en nauwkeurigheid. 

      De tips moeten gericht zijn op het versterken van zwakke punten en het benutten van sterke punten.
      
      Ontwikkeltips worden voor de kandidaat zelf geschreven zodat hij zichzelf verder kan ontwikkelen. Coaching Adviezen worden voor de manager geschreven om de kandidaat beter te begeleiden.

      Reageer in Markdown!!

      Geef een lijst met "Ontwikkelingstips" als titel en binnen dezd lijst twee sublijsten, namelijk: "Zelfontwikkeling", "Coaching". De volgende sublijsten moeten alleen tekst zijn zonder vetgedrukte titel
    
      De lijsten moeten genummerd worden als 1 (Ontwikkelingstips), 1.1 (Zelfontwikkeling), 1.2 (Coaching). De opsomming onder 1.1 en 1.2 moet weergegeven worden met bulletpoints/opsommingstekens.
    `,
    messages: [
      {
        role: 'user', content: `
          **INITIATIEF NEMEN**
          De kandidaat werkt mee aan opdrachten en initiatieven die binnen de dienst of afdeling genomen worden. Hij neemt op dat vlak zijn deel van de taken op. Dit gaat over initiatieven om het werk makkelijker te maken, efficiënter te laten verlopen, over het ontwikkelen of verder verfijnen van hulpmiddelen om goed overzicht te houden (het verder uitbouwen of updaten van checklijsten, ….). Hij werkt hier aan mee, ziet hier ook het nut van in, maar hij is niet iemand die dat zelf snel gaat initiëren. Op dat vlak stelt hij zich eerder uitvoerend op. Hij is trouwens ook niet de meest creatieve denker op dat vlak. Men kan er wel van op aan dat hij de zaken waar hij aan mee werkt goed doet. Op dat vlak geeft hij wel blijk van de nodige toewijding.
          **ZELFORGANISATIE**
          De betrokkene heeft graag een duidelijke structuur in het werk, heeft graag duidelijkheid rond wat er moet gedaan worden. Hij heeft de nodige vaardigheden om zijn werk op een voldoende gedegen manier te plannen en te organiseren. Hij gebruikt hiertoe de nodige hulpmiddelen om goed overzicht te houden (outlook, one drive,….) en om het werk in voor te bereiden. Dit laatste is voor hem toch wel belangrijk. Hij voelt er zich niet goed bij wanneer hij zomaar ad hoc aan iets moet beginnen. Dit laatste geldt evenzeer wanneer de planning, zijn structuur plots omgedraaid wordt, wanneer opdrachten die voorbereid zijn wegvallen, wanneer hij omwille van wisselende prioriteiten een opdracht dient te stoppen en aan iets anders te beginnen. Hier heeft hij het moeilijk mee, dit brengt ergernis mee. Hij mist op dat vlak wat flexibiliteit in denken. Voor hem is het belangrijk dat hij in een omgeving kan werken waar hij zijn werk goed kan voorbereiden en hij niet te veel voor verrassingen komt te staan.
          **LEERVERMOGEN**
          Het assimilatievermogen ligt op een goedgemiddelde niveau. Hij heeft de nodige alertheid om zich voldoende vlot in een nieuwe materie in te werken, zeker wanneer hij kan verder borduren op bestaande kennis. Hij is ook bereid om te leren, dit niet alleen puur naar kennis en kunde, maar ook op persoonlijk vlak. Hij is er op dat vlak ook niet bang van om info te vragen, te zoeken naar informatie bij mensen met de nodige kennis. Hij vindt het ook niet erg dat er de nodige opvolging is bij het aanleren van nieuwe gegevens, zodat hij op die manier ook de kans krijgt om zaken grondig te leren, er zeker van te zijn dat hij goed mee is. Zelf tracht hij vooral ook te leren van anderen, te zien hoe ervaren mensen de zaken aanpakken. 
          **SAMENWERKEN**
          De kandidaat is in een team niet iemand die zich sterk gaat manifesteren. Hij heeft er weinig nood aan om zich boven anderen te plaatsen. Hij is niet iemand die moeilijk gaat doen. Vraagt men hem iets dan komt het in orde. Hij heeft er ook geen moeite mee om collega’s te helpen wanneer ze hem dingen vragen, uitleg te geven of nuttige informatie, praktische informatie door te geven. Het gebeurt allemaal nog iets reactief in de zin dat het eerder gevraagd moet worden, dan dat hij hulp spontaan gaat aanbieden. In de contacten met anderen stelt hij zich vriendelijk op, ook naar derde partijen. Hij heeft er geen nood aan om sterk te instrueren of te commanderen. Hij let er wel op dat procedures gevolgd worden, dat mensen doen wat er van hen gevraagd wordt. Hij durft hen er wel op aanspreken wanneer dat niet het geval is. Op dat vlak kan hij ook wel vrij kordaat zijn. 
          **KLANTGERICHTHEID**
          Inzake klantgerichtheid doet hij vooral moeite om de klant een goed gevoel te geven, een oplossing te geven ook voor de vragen of problemen, waarvoor hij zich geplaatst voelt. Hij is een oplossingsgericht iemand, komt op dat vlak ook makkelijk zelf met voorstellen of ideeën om de klant verder te helpen. Hij tracht hierrond ook de nodige uitleg te geven, zijn redenering aan de klant mee te geven, zodat het voor deze laatste duidelijk is wat er gebeurt, hoe hij de zaken gaat oplossen. Dit helpt hem ook om eventuele ongenoegens van de klant te milderen. Heeft hij het gevoel dat een klant gelijk heeft met zijn ergernis of ongenoegen, dan heeft hij er ook geen moeite mee om zijn fout te erkennen en excuses aan te bieden. In globo kan hij een klant vrij makkelijk tegemoet komen. Hij dient er op te letten hier ook niet wat te ver in te gaan. Aan de andere kant komt hij er minder toe om de vraag of het probleem van de klant goed uit te diepen, zodat hij er zeker van is dat hij een goed en volledig beeld heeft van wat er precies aan de hand is. Hij wil vooral snel tot een oplossing komen, de klant tevreden stellen en vermijden in een confrontatie te komen.
          **PRAKTISCH DENKEN**
          Zoals eerder gezegd tracht hij zijn werk zo goed mogelijk voor te bereiden, zodat hij zo efficiënt mogelijk kan te werk gaan en hij zoveel mogelijk vermijdt om voor plotse problemen komt te staan. In de aanpak van problemen gaat hij in eerste instantie sterk terugvallen op procedures in werken, op gekende manieren van werken. Hij gaat hierbij stap voor stap te werk, volgt een logica in het analyseren van het probleem, in het zoeken naar de oorzaak van het probleem. De kandidaat staat voldoende sterk naar technisch inzicht toe om toch wel met wat complexere technische zaken om te kunnen. Hij gaat vooral voldoende bedachtzaam, voldoende overwogen te werk. Hij wil niet zomaar dingen uitproberen, neemt bewuste stappen in de aanpak van problemen, wil hier goed kunnen over nadenken. Dat maakt ook dat hij er zich minder goed bij voelt wanneer hij te veel met plotse, onvoorziene vragen of moeilijkheden te maken krijgt. Dit brengt hem meer wrevel dan een gevoel van uitdaging mee. Hij kent ook wel zijn grenzen, weet wanneer hij er zelf niet uitkomt, weet wanneer hij moet terugkoppelen naar anderen en doet dat ook. Voor hem primeert het dat het probleem op een accurate en efficiënte manier aangepakt wordt. Heeft hij daarvoor de hulp van anderen nodig dan roept hij deze in. Het algemeen redeneervermogen is voldoende sterk ontwikkeld. Cijfermatige, het goed inschatten van en omgaan met numeriek materiaal kost hem wel veel meer moeite. Hier heeft hij minder voeling mee.
          **STRESSBESTENDIGHEID**
          Komt hij onder stress te staan, dan verengt het denken wel wat bij de betrokkene. Hij is sowieso niet de meest creatieve denker, maar stress, druk versterkt dat nog een beetje. Juist in dat kader is het voor hem belangrijk dat hij in zijn werk zo veel mogelijk kan terugvallen op vast procedures en standaarden in werken aan de ene kant en dat hij aan de andere kant zijn zaken ook heel goed kan voorbereiden. Dit laatste sterkt zijn zelfvertrouwen. Is deze voorbereiding er niet dan brengt dat op zich wat frustratie, wat stress met zich mee. Ook wanneer hij problemen niet onmiddellijk kan oplossen en dat brengt voor de klant wat problemen mee, dan weegt dat ook wel wat op hem. Niet zozeer dat hij het niet kan oplossen, maar vooral dat de klanten in moeilijkheden blijven zitten is iets waar hij het lastig mee heeft. De betrokkene is zeker een gevoelig iemand, hij is niet iemand die dingen zomaar makkelijk over zich heen laat gaan.
          **NAUWKEURIGHEID**
          De kandidaat is een punctueel iemand, is een heel toegewijd iemand. Het werk moet goed gedaan zijn voor hem. Het moet vooral ook veilig gedaan zijn. Hij neemt zijn tijd om zaken goed te bekijken, goed voor te bereiden. Hij let er op dat dingen ook veilig gebeuren. Hij spreekt mensen er op aan wanneer dit niet het geval is. Hij is streng op het gebruik van persoonlijke beschermingsmiddelen, voor zichzelf maar ook voor anderen. Grondigheid en correctheid in werken, veiligheid in werken ook, tracht hij ook een stuk te garanderen door op voorhand goed te bekijken wat er moet gebeuren zodat de juiste middelen kunnen gebruikt worden, de juiste maatregelen kunnen genomen worden. Hij heeft ook de nodige aandacht voor nauwkeurigheid in werken, ook op administratief vlak. Hij tracht ook hier volledig te zijn in wat hij doet en binnen de gestelde termijnen te werken.
        `
      },
      {
        role: 'assistant',
        content: `
          1. **Ontwikkelingstips**

          1.1 **Zelfontwikkeling**
          -   Probeer in een groep meer naar voor te treden, zelf met ideeën te komen rond zaken die anders, beter of efficiënter kunnen. Probeer je op dat vlak je meer te laten zien. Hierdoor kan je aan impact winnen.    
          -   Probeer je niet te veel te laten opjagen door plotselinge of onvoorziene omstandigheden. Focus op het werk, op dat wat nu moet gebeuren. Probeer terug te vallen op je kennis en ervaring, blijf niet hangen bij een gebrek aan zekerheid, of aan voorbereiding. Laat je dan niet opwinden.
          -   Je wilt graag een goede indruk maken naar anderen toe. Let er wel op dat je niet te makkelijk meegaat in de redenering van anderen, laat je niet voor de kar spannen of gebruiken.
          1.1 **Coaching**
          -   De betrokkene heeft structuur in werken nodig. Betrek hem bij opdrachten waar hij zijn werk goed zelf kan regelen, waarbij hij de nodige voorbereidingstijd krijgt. Dit gaat zijn zelfzekerheid versterken.
          -   De kandidaat is iemand die goed overwogen en voorzichtig te werk gaat. Dit is zeker te waarderen. Let er wel op dat hij voldoende kort op de bal speelt en direct communiceert.
          -   Hij is niet zo sterk met cijfers. Hou hem in de gaten wanneer hij met grotere of complexe berekeningen te maken krijgt.
        `
      },
    ]
  };

  const orderMap = new Map(Object.keys(sections).map((key, index) => [key, index]));

  const generateTips = async () => {
    setGeneratingTips(true);

    const formValues = getResponses();
    const keys = Object.keys(formValues).map(el => `**${el.toUpperCase()}**\n`);
    const values = Object.values(formValues).map(el => `${el}\n`);
    const allValues = keys.map((key, index) => `${key}${values[index]}`).join('\n');

    const data = {
      system_prompt: points.system,
      temperature: 0.6,
      messages: [
        ...points.messages,
        { role: 'user', content: allValues }
      ],
    };

    const res = await axios.post(import.meta.env.VITE_API_URL + '/chat', data);
    setTips(res.data);
    console.log({ tips: res.data });
    setGeneratingTips(false);
  }


  const generate = async (value: string) => {
    setGenerating(value);
    const prompt = sections[value];
    const formValues = getValues();

    const data = {
      system_prompt: prompt.system,
      temperature: 0.7,
      messages: [
        ...prompt.messages,
        { role: 'user', content: formValues[value] }
      ],
      auth_info: authInfo
    };

    const res = await axios.post(import.meta.env.VITE_API_URL + '/chat', data);

    onEditOpen();
    setResponse(value, res.data);
    setEditValue(value);
    setGenerating('');
  }

  const generateAll = async (e: FormEvent) => {
    e.preventDefault();
    setGenerating('all');

    const keys = Object.keys(sections);
    const formValues = getValues();
    await Promise.all(keys.map(async (key) => {
      const prompt = sections[key];
      const data = {
        system_prompt: prompt.system,
        temperature: 0.7,
        messages: [
          ...prompt.messages,
          { role: 'user', content: formValues[key] }
        ],
        auth_info: authInfo
      };

      const res = await axios.post(import.meta.env.VITE_API_URL + '/chat', data);
      console.log({ key, data: res.data });
      setResponse(key, res.data);
    }));

    setGenerating('');
  }

  return (
    <div className="container row">
      <HStack position="absolute" top={8} left={8} spacing={4}>
        <Button
          colorScheme="gray"
          onClick={() => navigate('/')}
          variant="outline"
        >
          <FaHouse />
        </Button>
        <Button colorScheme="gray" onClick={onOpen} variant="outline">
          <HamburgerIcon />
        </Button>
      </HStack>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="md">
        <DrawerOverlay>
          <DrawerContent
            overflowY="auto"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#e2e8f066',
                borderRadius: '24px',
              },
            }}
          >
            <DrawerCloseButton />
            <DrawerHeader>{t('apps.fluvius.name')}</DrawerHeader>

            <form onSubmit={generateAll}>
              <DrawerBody>
                <Stack spacing={4}>
                  <FormControl>
                    <HStack justifyContent="space-between">
                      <FormLabel>
                        <Tooltip
                          label={t('apps.fluvius.form.initiatiefNemen.tooltip')}
                          fontSize="md"
                          arrowPadding={4}
                          hasArrow={true}
                          placement="bottom-start"
                          openDelay={100}
                        >
                          {t('apps.fluvius.form.initiatiefNemen.title')}
                        </Tooltip>
                      </FormLabel>
                      <HStack>
                        <Button
                          size="xs"
                          isLoading={generating === 'initiatiefNemen'}
                          isDisabled={generating !== ''}
                          onClick={() => {
                            setEditValue('initiatiefNemen');
                            onEditOpen();
                          }}
                        >
                          {t('buttons.edit')}
                        </Button>
                        <Button
                          size="xs"
                          isLoading={generating === 'initiatiefNemen'}
                          isDisabled={generating !== ''}
                          onClick={() => generate('initiatiefNemen')}
                        >
                          {t('buttons.generate')}
                        </Button>
                      </HStack>
                    </HStack>
                    <AutoResizeTextarea
                      placeholder={t(
                        'apps.fluvius.form.initiatiefNemen.placeholder'
                      )}
                      id="initiatiefNemen"
                      {...register('initiatiefNemen')}
                      maxRows={5}
                      style={{ overflowY: 'scroll' }}
                    />
                  </FormControl>

                  <FormControl>
                    <HStack justifyContent="space-between">
                      <FormLabel>
                        <Tooltip
                          label={t('apps.fluvius.form.zelforganisatie.tooltip')}
                          fontSize="md"
                          arrowPadding={4}
                          hasArrow={true}
                          placement="bottom-start"
                          openDelay={100}
                        >
                          {t('apps.fluvius.form.zelforganisatie.title')}
                        </Tooltip>
                      </FormLabel>
                      <HStack>
                        <Button
                          size="xs"
                          isLoading={generating === 'zelforganisatie'}
                          isDisabled={generating !== ''}
                          onClick={() => {
                            setEditValue('zelforganisatie');
                            onEditOpen();
                          }}
                        >
                          {t('buttons.edit')}
                        </Button>
                        <Button
                          size="xs"
                          isLoading={generating === 'zelforganisatie'}
                          isDisabled={generating !== ''}
                          onClick={() => generate('zelforganisatie')}
                        >
                          {t('buttons.generate')}
                        </Button>
                      </HStack>
                    </HStack>
                    <AutoResizeTextarea
                      placeholder={t(
                        'apps.fluvius.form.zelforganisatie.placeholder'
                      )}
                      id="zelforganisatie"
                      {...register('zelforganisatie')}
                      maxRows={5}
                      style={{ overflowY: 'scroll' }}
                    />
                  </FormControl>

                  <FormControl>
                    <HStack justifyContent="space-between">
                      <FormLabel>
                        <Tooltip
                          label={t('apps.fluvius.form.leervermogen.tooltip')}
                          fontSize="md"
                          arrowPadding={4}
                          hasArrow={true}
                          placement="bottom-start"
                          openDelay={100}
                        >
                          {t('apps.fluvius.form.leervermogen.title')}
                        </Tooltip>
                      </FormLabel>
                      <HStack>
                        <Button
                          size="xs"
                          isLoading={generating === 'leervermogen'}
                          isDisabled={generating !== ''}
                          onClick={() => {
                            setEditValue('leervermogen');
                            onEditOpen();
                          }}
                        >
                          {t('buttons.edit')}
                        </Button>
                        <Button
                          size="xs"
                          isLoading={generating === 'leervermogen'}
                          isDisabled={generating !== ''}
                          onClick={() => generate('leervermogen')}
                        >
                          {t('buttons.generate')}
                        </Button>
                      </HStack>
                    </HStack>
                    <AutoResizeTextarea
                      placeholder={t(
                        'apps.fluvius.form.leervermogen.placeholder'
                      )}
                      id="leervermogen"
                      {...register('leervermogen')}
                      maxRows={5}
                      style={{ overflowY: 'scroll' }}
                    />
                  </FormControl>

                  <FormControl>
                    <HStack justifyContent="space-between">
                      <FormLabel>
                        <Tooltip
                          label={t('apps.fluvius.form.samenwerken.tooltip')}
                          fontSize="md"
                          arrowPadding={4}
                          hasArrow={true}
                          placement="bottom-start"
                          openDelay={100}
                        >
                          {t('apps.fluvius.form.samenwerken.title')}
                        </Tooltip>
                      </FormLabel>
                      <HStack>
                        <Button
                          size="xs"
                          isLoading={generating === 'samenwerken'}
                          isDisabled={generating !== ''}
                          onClick={() => {
                            setEditValue('samenwerken');
                            onEditOpen();
                          }}
                        >
                          {t('buttons.edit')}
                        </Button>
                        <Button
                          size="xs"
                          isLoading={generating === 'samenwerken'}
                          isDisabled={generating !== ''}
                          onClick={() => generate('samenwerken')}
                        >
                          {t('buttons.generate')}
                        </Button>
                      </HStack>
                    </HStack>
                    <AutoResizeTextarea
                      placeholder={t(
                        'apps.fluvius.form.samenwerken.placeholder'
                      )}
                      id="samenwerken"
                      {...register('samenwerken')}
                      maxRows={5}
                      style={{ overflowY: 'scroll' }}
                    />
                  </FormControl>

                  <FormControl>
                    <HStack justifyContent="space-between">
                      <FormLabel>
                        <Tooltip
                          label={t('apps.fluvius.form.klantgerichtheid.tooltip')}
                          fontSize="md"
                          arrowPadding={4}
                          hasArrow={true}
                          placement="bottom-start"
                          openDelay={100}
                        >
                          {t('apps.fluvius.form.klantgerichtheid.title')}
                        </Tooltip>
                      </FormLabel>
                      <HStack>
                        <Button
                          size="xs"
                          isLoading={generating === 'klantgerichtheid'}
                          isDisabled={generating !== ''}
                          onClick={() => {
                            setEditValue('klantgerichtheid');
                            onEditOpen();
                          }}
                        >
                          {t('buttons.edit')}
                        </Button>
                        <Button
                          size="xs"
                          isLoading={generating === 'klantgerichtheid'}
                          isDisabled={generating !== ''}
                          onClick={() => generate('klantgerichtheid')}
                        >
                          {t('buttons.generate')}
                        </Button>
                      </HStack>
                    </HStack>
                    <AutoResizeTextarea
                      placeholder={t(
                        'apps.fluvius.form.klantgerichtheid.placeholder'
                      )}
                      id="klantgerichtheid"
                      {...register('klantgerichtheid')}
                      maxRows={5}
                      style={{ overflowY: 'scroll' }}
                    />
                  </FormControl>

                  <FormControl>
                    <HStack justifyContent="space-between">
                      <FormLabel>
                        <Tooltip
                          label={t('apps.fluvius.form.praktischDenken.tooltip')}
                          fontSize="md"
                          arrowPadding={4}
                          hasArrow={true}
                          placement="bottom-start"
                          openDelay={100}
                        >
                          {t('apps.fluvius.form.praktischDenken.title')}
                        </Tooltip>
                      </FormLabel>
                      <HStack>
                        <Button
                          size="xs"
                          isLoading={generating === 'praktischDenken'}
                          isDisabled={generating !== ''}
                          onClick={() => {
                            setEditValue('praktischDenken');
                            onEditOpen();
                          }}
                        >
                          {t('buttons.edit')}
                        </Button>
                        <Button
                          size="xs"
                          isLoading={generating === 'praktischDenken'}
                          isDisabled={generating !== ''}
                          onClick={() => generate('praktischDenken')}
                        >
                          {t('buttons.generate')}
                        </Button>
                      </HStack>
                    </HStack>
                    <AutoResizeTextarea
                      placeholder={t(
                        'apps.fluvius.form.praktischDenken.placeholder'
                      )}
                      id="praktischDenken"
                      {...register('praktischDenken')}
                      maxRows={5}
                      style={{ overflowY: 'scroll' }}
                    />
                  </FormControl>

                  <FormControl>
                    <HStack justifyContent="space-between">
                      <FormLabel>
                        <Tooltip
                          label={t('apps.fluvius.form.stressbestendigheid.tooltip')}
                          fontSize="md"
                          arrowPadding={4}
                          hasArrow={true}
                          placement="bottom-start"
                          openDelay={100}
                        >
                          {t('apps.fluvius.form.stressbestendigheid.title')}
                        </Tooltip>
                      </FormLabel>
                      <HStack>
                        <Button
                          size="xs"
                          isLoading={generating === 'stressbestendigheid'}
                          isDisabled={generating !== ''}
                          onClick={() => {
                            setEditValue('stressbestendigheid');
                            onEditOpen();
                          }}
                        >
                          {t('buttons.edit')}
                        </Button>
                        <Button
                          size="xs"
                          isLoading={generating === 'stressbestendigheid'}
                          isDisabled={generating !== ''}
                          onClick={() => generate('stressbestendigheid')}
                        >
                          {t('buttons.generate')}
                        </Button>
                      </HStack>
                    </HStack>
                    <AutoResizeTextarea
                      placeholder={t(
                        'apps.fluvius.form.stressbestendigheid.placeholder'
                      )}
                      id="stressbestendigheid"
                      {...register('stressbestendigheid')}
                      maxRows={5}
                      style={{ overflowY: 'scroll' }}
                    />
                  </FormControl>

                  <FormControl>
                    <HStack justifyContent="space-between">
                      <FormLabel>
                        <Tooltip
                          label={t('apps.fluvius.form.nauwkeurigheid.tooltip')}
                          fontSize="md"
                          arrowPadding={4}
                          hasArrow={true}
                          placement="bottom-start"
                          openDelay={100}
                        >
                          {t('apps.fluvius.form.nauwkeurigheid.title')}
                        </Tooltip>
                      </FormLabel>
                      <HStack>
                        <Button
                          size="xs"
                          isLoading={generating === 'nauwkeurigheid'}
                          isDisabled={generating !== ''}
                          onClick={() => {
                            setEditValue('nauwkeurigheid');
                            onEditOpen();
                          }}
                        >
                          {t('buttons.edit')}
                        </Button>
                        <Button
                          size="xs"
                          isLoading={generating === 'nauwkeurigheid'}
                          isDisabled={generating !== ''}
                          onClick={() => generate('nauwkeurigheid')}
                        >
                          {t('buttons.generate')}
                        </Button>
                      </HStack>
                    </HStack>
                    <AutoResizeTextarea
                      placeholder={t(
                        'apps.fluvius.form.nauwkeurigheid.placeholder'
                      )}
                      id="nauwkeurigheid"
                      {...register('nauwkeurigheid')}
                      maxRows={5}
                      style={{ overflowY: 'scroll' }}
                    />
                  </FormControl>
                </Stack>
              </DrawerBody>

              <DrawerFooter>
                <Button
                  background="#e19d2e44"
                  color="#e19d2e"
                  isLoading={isSubmitting}
                  type="submit"
                >
                  {t('buttons.generate')}
                </Button>
              </DrawerFooter>
            </form>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <Flex
        width={'100vw'}
        height={'100vh'}
        alignContent={'center'}
        justifyContent={'center'}
      >
        <ThemeSelector />
        <LanguageSelector />

        <Center w="100%">
          {loading === false && Object.keys(getResponses()).length === 0 ? (
            <VStack>
              <Image src={butterfly} alt="Logo" />
              <Text fontSize="x-large" fontWeight="semibold" color={text}>
                Assess-Mate
              </Text>
            </VStack>
          ) : (
            loading && (
              <HashLoader
                color={'#E19D2E'}
                loading={loading}
                cssOverride={override}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )
          )}

          {Object.keys(getResponses()).length > 0 && (
            <Container
              maxW="container.lg"
              h="75%"
              overflowY="auto"
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#e2e8f066',
                  borderRadius: '24px',
                },
              }}
            >
              <Tabs>
                <TabList>
                  <Tab>Competenties</Tab>
                  <Tab>Ontwikkelpunten</Tab>
                  <Button
                    position="absolute"
                    right={0}
                    top={0}
                    variant="ghost"
                  >
                    <FaCopy />
                  </Button>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    {
                      Object.keys(getResponses()).sort((a, b) => orderMap.get(a)! - orderMap.get(b)!).map((key) => (
                        <Box key={key} mb={8} position="relative">
                          <HStack>
                            <Heading size="md" mb={2}>{t(`apps.fluvius.form.${key}.title`)}</Heading>
                            <Button
                              size="xs"
                              onClick={() => {
                                setEditValue(key);
                                onEditOpen();
                              }}
                            >
                              {t('buttons.edit')}
                            </Button>
                          </HStack>
                          <Text>{getResponses()[key]}</Text>
                        </Box>
                      ))
                    }
                  </TabPanel>
                  <TabPanel>
                    <Box mb={8}>
                      <Text size="sm" mb={4}>Hier kan je voor dit rapport ontwikkelpunten genereren toevoegen om aan het verslag toe te voegen. Hou er rekening mee dat het AI model kan hallucineren.</Text>
                      <Button
                        onClick={generateTips}
                        mb={4}
                        isLoading={generatingTips}
                      >
                        {t('buttons.generate')}
                      </Button>
                      <Markdown children={tips} className="react-markdown-class-ref" />
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Container>
          )}
        </Center>
      </Flex>

      <Modal isOpen={isEditOpen} onClose={onEditClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t(`apps.fluvius.form.${editValue}.title`)}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AutoResizeTextarea
              placeholder={t(`apps.fluvius.form.${editValue}.placeholder`)}
              id={editValue}
              {...registerResponse(editValue, {
                required: 'This is required',
              })}
              maxRows={40}
              style={{ overflowY: 'scroll' }}
            />
          </ModalBody>

          <ModalFooter>
            <FeedbackContainer
              inline={true}
              margin={4}
              requestId={requestId}
              data={getResponses()[editValue]}
              markdownAsHtml={getResponses()[editValue]}
            />
            <Button
              background="#e19d2e44"
              color="#e19d2e"
              colorScheme="blue"
              onClick={onEditClose}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
