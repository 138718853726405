import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationNL from './assets/locales/nl/translation.json';
import translationEN from './assets/locales/en/translation.json';
import translationFR from './assets/locales/fr/translation.json';

const resources = {
  nl: {
    translation: translationNL,
  },
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

const languageDetector = new LanguageDetector(null, {
  lookupQuerystring: 'lang',
});

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    resources,
    fallbackLng: 'nl',
    lng: 'nl',
    load: 'languageOnly',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
