import { AuthInfo, ClientPrincipal } from "../../types/authTypes";

export const pseudoAuthInfo: AuthInfo = {
    clientPrincipal:  {
        claims: [{ typ: "aud", val: "44228961-6271-4098-b81c-8c9a218b6a79" },
        { typ: "iss", val: "https://login.microsoftonline.com/15eb57d7-4484-4134-a279-8bfa501b9c09/v2.0" },
        { typ: "iat", val: "1705486013" },
        { typ: "nbf", val: "1705486013" },
        { typ: "exp", val: "1705489913" },
        { typ: "aio", val: "ATQAy/8VAAAApx6kPd7plHH4D3BTuHCoQhOJTuYvepjHUNIXBO0Ba7ASK7sEYXXYYAotH/FLGIVp" },
        { typ: "c_hash", val: "cfbl-PY8rcrgfRAf0WjkTQ" },
        { typ: "name", val: "Assess Mate test user" },
        { typ: "nonce", val: "5d6f882e53da4fbab1e2f4670f64355d_20240117101647" },
        { typ: "http://schemas.microsoft.com/identity/claims/objectidentifier", val: "2b45b3a0-d25d-4b9d-bf1c-0a2d8d082e65" },
        { typ: "preferred_username", val: "assess_mate_test@itzu.eu" },
        { typ: "rh", val: "0.AVwA11frFYRENEGieYv6UBucCWGJIkRxYphAuByMmiGLanlcAMo." },
        { typ: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier", val: "yp7A9ctNvtDWYXMLCNS9qaokNoorgC3VQQPmpbwsqoM" },
        { typ: "http://schemas.microsoft.com/identity/claims/tenantid", val: "15eb57d7-4484-4134-a279-8bfa501b9c09" },
        { typ: "uti", val: "HKKiGdfBJkaOSyd6TiQbAA" },
        { typ: "ver", val: "2.0" }],
        identityProvider: "aad",
        userDetails: "assess_mate_test@itzu.eu",
        userId: "2b45b3a0-d25d-4b9d-bf1c-0a2d8d082e65",
        userRoles: [
            "authenticated",
            "anonymous"
        ],
        info: "This is pseudo AuthInfo. This means there is not a real user signed in. This pseudo info is set to have authentication info available in development mode for testing. For example testing the logging of usernames to promptlayer when doing a competence request."
    }
}
