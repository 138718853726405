import butterfly from '../assets/butterfly.png';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import useAuthInfo from '../hooks/useAuthInfo';
import { Button, Center, Flex, HStack, VStack, Text, Container, Image, useColorModeValue } from '@chakra-ui/react';
import { FaHouse } from 'react-icons/fa6';
import { HamburgerIcon } from '@chakra-ui/icons';
import ThemeSelector from '../components/ThemeSelector';
import LanguageSelector from '../components/LanguageSelector';
import Markdown from 'react-markdown';
import { useTranslation } from 'react-i18next';

interface ClientPrincipal {
  identityProvider: string;
  userId: string;
  userDetails: string;
  userRoles: string[];
}

interface AuthInfo {
  clientPrincipal: ClientPrincipal | null;
}

export default function Welcome() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { authInfo } = useAuthInfo()

  const text = useColorModeValue('gray.600', 'gray.400');


  console.log(authInfo);

  const successUrl = 'https://assess-mate.meetwonka.com';
  const loginUrl = `/.auth/login/aad?post_login_redirect_uri=${import.meta.env.VITE_HOMEPAGE}`;
  const logoutUrl = `/.auth/logout?post_logout_redirect_uri=${import.meta.env.VITE_HOMEPAGE}`;

  if (authInfo) console.log(authInfo)

  return (
    <div className="container row">
      {/* <HStack position="absolute" top={8} left={8} spacing={4}>
        <Button
          colorScheme="gray"
          onClick={() => navigate('/')}
          variant="outline"
        >
          <FaHouse />
        </Button>
      </HStack> */}
      <Flex
        width={'100vw'}
        height={'100vh'}
        alignContent={'center'}
        justifyContent={'center'}
      >
        <ThemeSelector />
        <LanguageSelector />

        <Center w="100%">

          <Center>     <VStack  margin={'auto'} spacing={16} paddingX={{ base: 0, lg: 24 }} position="relative">

            <VStack margin='auto' >
              <VStack>
                <Image src={butterfly} alt="Logo" />
                <Text fontSize="x-large" fontWeight="semibold" color={text}>
                  Assess-Mate
                </Text>
                <Text>{t('apps.welcome.description')}</Text>
              </VStack>

              <HStack >
                <a className="no-underline" href={loginUrl}>
                  <Button className="button-red">
                    <Text>{t('apps.welcome.login')}</Text>
                  </Button>
                </a>
                {authInfo?.clientPrincipal?.userDetails && <a className="no-underline" href={logoutUrl}>
                  <Button className="button-red">
                    <Text>{t('apps.welcome.logout')}</Text>
                  </Button>
                </a>}
              </HStack>
              <div>
                {authInfo?.clientPrincipal?.userDetails && <Text>
                 {t('apps.welcome.currentUser')}{': '}
                  {authInfo?.clientPrincipal?.userDetails}
                </Text>}
              </div>
            </VStack>
          </VStack></Center>


        </Center>
      </Flex>

    </div>
  );
}
