import React from 'react';
import butterfly from '../../assets/butterfly.png';
import {
  Input,
  Select,
  Button,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  FormControl,
  FormLabel,
  Text,
  Image,
  Flex,
  Center,
  VStack,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Container,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import './textstyles.css';
import { HashLoader } from 'react-spinners';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../components/LanguageSelector';
import { FieldValues, useForm } from 'react-hook-form';
import { AutoResizeTextarea } from '../../components/AutoResizeTextarea';
import { FaHouse } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import AdjustmentForm from '../../components/AdjustmentForm';
import Markdown from 'react-markdown';
import FeedbackContainer from '../../components/FeedbackContainer';
import ThemeSelector from '../../components/ThemeSelector';
import useAuthInfo from '../../hooks/useAuthInfo';

type ValueLabel = {
  value: string;
  label: string;
};

const override = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};

export default function MotivationApp() {
  const { t, i18n } = useTranslation();

  const {authInfo} = useAuthInfo()

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const [data, setData] = React.useState('');
  const [markdownAsHtml, setMarkDownAsHtml] = React.useState('');
  const [requestId, setRequestId] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [editValue, setEditValue] = React.useState('');

  const text = useColorModeValue('gray.600', 'gray.400');

  const openEditModal = (value: string) => {
    setEditValue(value);
    onEditOpen();
  };

  // Hook to save the markdown state as a html state (for clipboard copy button)
  React.useEffect(() => {
    const el = document.querySelector('.react-markdown-class-ref');
    if (el) {
        const mdHTML = el.innerHTML;
        setMarkDownAsHtml(mdHTML)
    }
}, [data]);

  const onSubmit = (values: FieldValues) => {
    return new Promise(async (resolve: any, reject) => {
      const data = {
        carreer_notes: values.careerNotes,
        gender: values.gender ? t(`apps.motivatie.form.gender.options.${values.gender}.value`) : '',
        name: values.name,
        other_notes: values.otherNotes,
        position: values.position,
        test_results: values.testResults,
        language: i18n.language,
        tone: values.tone ? t(`apps.motivatie.form.tone.options.${values.tone}.value`) : '',
        auth_info: authInfo,
      };

      try {
        let buffer = '';
        setLoading(true);
        onClose();
        await fetchEventSource(
          import.meta.env.VITE_API_URL + '/motivation_summary',
          {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json',
            },
            openWhenHidden: true,
            body: JSON.stringify(data),
            onmessage({ event, data }) {
              if (event === 'pl_request_id') {
                setRequestId(data);
                setLoading(false);
                resolve();
              } else if (event === 'data') {
                buffer += data;
                setData(buffer);
              }
            },
            onerror(err) {
              reject(err);
            },
          }
        );
      } catch (err) {
        reject(err);
      }
    });
  };

  return (
    <div className="container row">
      <HStack position="absolute" top={8} left={8} spacing={4}>
        <Button
          colorScheme="gray"
          onClick={() => navigate('/')}
          variant="outline"
        >
          <FaHouse />
        </Button>
        <Button colorScheme="gray" onClick={onOpen} variant="outline">
          <HamburgerIcon />
        </Button>
      </HStack>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent
          overflowY="auto"
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#e2e8f066',
              borderRadius: '24px',
            },
          }}
        >
          <DrawerCloseButton />
          <DrawerHeader>{t('apps.motivatie.name')}</DrawerHeader>

          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerBody>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel htmlFor="name">
                    <Tooltip
                      label={t('apps.motivatie.form.name.tooltip')}
                      fontSize="md"
                      arrowPadding={4}
                      hasArrow={true}
                      placement="bottom-start"
                      openDelay={100}
                    >
                      {t('apps.motivatie.form.name.title')}
                    </Tooltip>
                  </FormLabel>
                  <Input
                    placeholder={t('apps.motivatie.form.name.placeholder')}
                    id="name"
                    {...register('name')}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="gender">
                    <Tooltip
                      label={t('apps.motivatie.form.gender.tooltip')}
                      fontSize="md"
                      arrowPadding={4}
                      hasArrow={true}
                      placement="bottom-start"
                      openDelay={100}
                    >
                      {t('apps.motivatie.form.gender.title')}
                    </Tooltip>
                  </FormLabel>
                  <Select id="gender" {...register('gender')}>
                    <option selected hidden disabled value="">
                      {t('apps.motivatie.form.gender.placeholder')}
                    </option>
                    {(
                      t('apps.motivatie.form.gender.options', {
                        returnObjects: true,
                      }) as ValueLabel[]
                    ).map((el, idx) => (
                      <option value={idx} key={idx}>
                        {el.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="position">
                    <Tooltip
                      label={t('apps.motivatie.form.position.tooltip')}
                      fontSize="md"
                      arrowPadding={4}
                      hasArrow={true}
                      placement="bottom-start"
                      openDelay={100}
                    >
                      {t('apps.motivatie.form.position.title')}
                    </Tooltip>
                  </FormLabel>
                  <Input
                    placeholder={t('apps.motivatie.form.position.placeholder')}
                    id="name"
                    {...register('position', {
                      required: 'This is required',
                    })}
                  />
                </FormControl>

                <FormControl isRequired>
                  <HStack justifyContent="space-between">
                    <FormLabel>
                      <Tooltip
                        label={t('apps.motivatie.form.careerNotes.tooltip')}
                        fontSize="md"
                        arrowPadding={4}
                        hasArrow={true}
                        placement="bottom-start"
                        openDelay={100}
                      >
                        {t('apps.motivatie.form.careerNotes.title')}
                      </Tooltip>
                    </FormLabel>
                    <Button
                      size="xs"
                      onClick={() => openEditModal('careerNotes')}
                    >
                      {t('buttons.edit')}
                    </Button>
                  </HStack>
                  <AutoResizeTextarea
                    placeholder={t(
                      'apps.motivatie.form.careerNotes.placeholder'
                    )}
                    id="careerNotes"
                    {...register('careerNotes', {
                      required: 'This is required',
                    })}
                    maxRows={5}
                    style={{overflowY: 'scroll'}}
                  />
                </FormControl>

                <FormControl>
                  <HStack justifyContent="space-between">
                    <FormLabel>
                      <Tooltip
                        label={t('apps.motivatie.form.testResults.tooltip')}
                        fontSize="md"
                        arrowPadding={4}
                        hasArrow={true}
                        placement="bottom-start"
                        openDelay={100}
                      >
                        {t('apps.motivatie.form.testResults.title')}
                      </Tooltip>
                    </FormLabel>
                    <Button
                      size="xs"
                      onClick={() => openEditModal('testResults')}
                    >
                      {t('buttons.edit')}
                    </Button>
                  </HStack>
                  <AutoResizeTextarea
                    placeholder={t(
                      'apps.motivatie.form.testResults.placeholder'
                    )}
                    id="testResults"
                    {...register('testResults')}
                    maxRows={5}
                    style={{overflowY: 'scroll'}}
                  />
                </FormControl>

                <FormControl>
                  <HStack justifyContent="space-between">
                    <FormLabel>
                      <Tooltip
                        label={t('apps.motivatie.form.otherNotes.tooltip')}
                        fontSize="md"
                        arrowPadding={4}
                        hasArrow={true}
                        placement="bottom-start"
                        openDelay={100}
                      >
                        {t('apps.motivatie.form.otherNotes.title')}
                      </Tooltip>
                    </FormLabel>
                    <Button
                      size="xs"
                      onClick={() => openEditModal('otherNotes')}
                    >
                      {t('buttons.edit')}
                    </Button>
                  </HStack>
                  <AutoResizeTextarea
                    placeholder={t(
                      'apps.motivatie.form.otherNotes.placeholder'
                    )}
                    id="otherNotes"
                    {...register('otherNotes')}
                    maxRows={5}
                    style={{overflowY: 'scroll'}}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>
                    <Tooltip
                      label={t('apps.motivatie.form.tone.tooltip')}
                      fontSize="md"
                      arrowPadding={4}
                      hasArrow={true}
                      placement="bottom-start"
                      openDelay={100}
                    >
                      {t('apps.motivatie.form.tone.title')}
                    </Tooltip>
                  </FormLabel>
                  <Select id="tone" {...register('tone')}>
                    <option selected hidden disabled value="">
                      {t('apps.motivatie.form.tone.placeholder')}
                    </option>
                    {(
                      t('apps.motivatie.form.tone.options', {
                        returnObjects: true,
                      }) as ValueLabel[]
                    ).map((el, idx) => (
                      <option value={idx} key={idx}>
                        {el.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </DrawerBody>

            <DrawerFooter>
              <Button
                background="#e19d2e44"
                color="#e19d2e"
                isLoading={isSubmitting}
                type="submit"
              >
                {t('apps.motivatie.form.submit')}
              </Button>
            </DrawerFooter>
          </form>
        </DrawerContent>
      </Drawer>
      <Flex
        width={'100vw'}
        height={'100vh'}
        alignContent={'center'}
        justifyContent={'center'}
      >
        <ThemeSelector />
        <LanguageSelector />

        <Center w="100%">
          {loading === false && data === '' ? (
            <VStack>
              <Image src={butterfly} alt="Logo" />
              <Text fontSize="x-large" fontWeight="semibold" color={text}>
                Assess-Mate
              </Text>
            </VStack>
          ) : (
            loading === true &&
            data === '' && (
              <HashLoader
                color={'#E19D2E'}
                loading={loading}
                cssOverride={override}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )
          )}

          {data !== '' && (
            <VStack
              w="100%"
              h="100%"
              justifyContent="space-between"
              paddingTop={24}
              paddingBottom={12}
            >
              <Container
                maxW="container.xl"
                overflowY="auto"
                css={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#e2e8f066',
                    borderRadius: '24px',
                  },
                }}
              >
                <HStack alignItems="start" spacing={16} paddingX={{ base: 0, lg: 24 }} position="relative">
                  <Image src={butterfly} height={8} marginLeft={-24}/>
                  <Text maxW={'container.md'} width={'container.md'} marginX={'auto'}>
                    <Markdown children={data} className="react-markdown-class-ref"/>
                  </Text>
                  {!isSubmitting && (
                    <FeedbackContainer requestId={requestId} data={data}  markdownAsHtml={markdownAsHtml}/>
                  )}
                </HStack>
              </Container>

              <AdjustmentForm
                data={data}
                disabled={isSubmitting}
                setData={setData}
                setRequestId={setRequestId}
              />
            </VStack>
          )}
        </Center>
      </Flex>

      <Modal isOpen={isEditOpen} onClose={onEditClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t(`apps.motivatie.form.${editValue}.title`)}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AutoResizeTextarea
              placeholder={t(`apps.motivatie.form.${editValue}.placeholder`)}
              id={editValue}
              {...register(editValue, {
                required: 'This is required',
              })}
              maxRows={40}
              style={{overflowY: 'scroll'}}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              background="#e19d2e44"
              color="#e19d2e"
              colorScheme="blue"
              onClick={onEditClose}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
