import React, { ChangeEvent } from 'react';
import butterfly from '../../assets/butterfly.png';
import {
  Input,
  Select,
  Button,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  FormControl,
  FormLabel,
  Text,
  Image,
  Flex,
  Center,
  VStack,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Container,
  Tooltip,
  useColorModeValue,
  Badge,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import './textstyles.css';
import { HashLoader } from 'react-spinners';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../components/LanguageSelector';
import { FieldValues, useForm } from 'react-hook-form';
import { AutoResizeTextarea } from '../../components/AutoResizeTextarea';
import { FaHouse } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import AdjustmentForm from '../../components/AdjustmentForm';
import Markdown from 'react-markdown';
import FeedbackContainer from '../../components/FeedbackContainer';
import ThemeSelector from '../../components/ThemeSelector';
import useAuthInfo from '../../hooks/useAuthInfo';

type ValueLabel = {
  value: string;
  label: string;
};

const override = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};

export default function CompetencyApp() {
  const { t, i18n } = useTranslation();

  const { authInfo } = useAuthInfo();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const [selectOther, setSelectOther] = React.useState(false);
  const [data, setData] = React.useState('');
  const [markdownAsHtml, setMarkDownAsHtml] = React.useState('');
  const [requestId, setRequestId] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [editValue, setEditValue] = React.useState('');
  const [willUseDefinitions, setWillUseDefinitions] = React.useState(false);

  const text = useColorModeValue('gray.600', 'gray.400');

  const openEditModal = (value: string) => {
    setEditValue(value);
    onEditOpen();
  };

  // Hook to save the markdown state as a html state (for clipboard copy button)
  React.useEffect(() => {
    const el = document.querySelector('.react-markdown-class-ref');
    if (el) {
      const mdHTML = el.innerHTML;
      setMarkDownAsHtml(mdHTML);
    }
  }, [data]);

  const onSubmit = (values: FieldValues) => {
    return new Promise(async (resolve: any, reject) => {
      const data = {
        competence: String(t(
          `apps.competentie.form.competency.options.${values.competence}.value`,
          values.competence
        )) + (willUseDefinitions? '': ' '),
        fixed_test_results: values.psytechTest,
        free_test_results: values.simTest,
        other_notes: values.otherNotes,
        gender: values.gender
          ? t(`apps.competentie.form.gender.options.${values.gender}.value`)
          : '',
        level: values.level
          ? t(`apps.competentie.form.level.options.${values.level}.value`)
          : '',
        name: values.name,
        response_type: values.responseType
          ? t(
              `apps.competentie.form.responseType.options.${values.responseType}.value`
            )
          : '',
        tone: values.tone
          ? t(`apps.competentie.form.tone.options.${values.tone}.value`)
          : '',
        language: i18n.language,
        auth_info: authInfo,
      };

      try {
        console.log('backend will be used', import.meta.env.VITE_API_URL)
        let buffer = '';
        setLoading(true);
        onClose();
        await fetchEventSource(
          import.meta.env.VITE_API_URL + '/competence_summary',
          {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json',
            },
            openWhenHidden: true,
            body: JSON.stringify(data),
            onmessage({ event, data }) {
              if (event === 'pl_request_id') {
                setRequestId(data);
                setLoading(false);
                resolve();
              } else if (event === 'data') {
                buffer += data;
                setData(buffer);
              }
            },
            onerror(err) {
              reject(err);
            },
          }
        );
      } catch (err) {
        reject(err);
      }
    });
  };

  // Function to combine refs
  const setRef = (element: HTMLInputElement) => {
    competenceInputRef.current = element;

    // Call the ref function from `register` if it exists
    if (typeof register('competence').ref === 'function') {
      register('competence').ref(element);
    }
  };

  // Ensure that the freeform competency input immediately becomes focused
  React.useEffect(() => {
    if (selectOther && competenceInputRef.current) {
      competenceInputRef.current.focus();
    }
  }, [selectOther]);

  const competenceInputRef = React.useRef<HTMLInputElement | null>(null);

  const onCompetenceChange = (e: ChangeEvent<HTMLSelectElement>) => {
    let value = t(
      `apps.competentie.form.competency.options.${e.target.value}.value`
    );

    // When typing freeform, make sure that definitions will not be used (being appedending a space)
    if (selectOther){
      value = value+ ' '
    }

    // If competence was not found in the optons
    if (
      `apps.competentie.form.competency.options.${e.target.value}.value` ===
      value
    ) {
      setWillUseDefinitions(false);
    } else {
      setWillUseDefinitions(true);
    }

    if (
      value === 'Eigen competentie formuleren...' ||
      value === 'Formulate own competency...' ||
      value === 'Autre'
    ) {
      setSelectOther(true);
      setWillUseDefinitions(false)
      setValue('competence', '');
    }
  };

  const resetCompetence = () => {
    setValue('competence', '');
    setSelectOther(false);
  };

  return (
    <div className="container row">
      <HStack position="absolute" top={8} left={8} spacing={4}>
        <Button
          colorScheme="gray"
          onClick={() => navigate('/')}
          variant="outline"
        >
          <FaHouse />
        </Button>
        <Button colorScheme="gray" onClick={onOpen} variant="outline">
          <HamburgerIcon />
        </Button>
      </HStack>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="md">
        <DrawerOverlay>
          <DrawerContent
            overflowY="auto"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#e2e8f066',
                borderRadius: '24px',
              },
            }}
          >
            <DrawerCloseButton />
            <DrawerHeader>{t('apps.competentie.name')}</DrawerHeader>

            <form onSubmit={handleSubmit(onSubmit)}>
              <DrawerBody>
                <Stack spacing={4}>
                  <FormControl>
                    <FormLabel htmlFor="name">
                      <Tooltip
                        label={t('apps.competentie.form.name.tooltip')}
                        fontSize="md"
                        arrowPadding={4}
                        hasArrow={true}
                        placement="bottom-start"
                        openDelay={100}
                      >
                        {t('apps.competentie.form.name.title')}
                      </Tooltip>
                    </FormLabel>
                    <Input
                      placeholder={t('apps.competentie.form.name.placeholder')}
                      id="name"
                      size="md"
                      {...register('name')}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel htmlFor="gender">
                      <Tooltip
                        label={t('apps.competentie.form.gender.tooltip')}
                        fontSize="md"
                        arrowPadding={4}
                        hasArrow={true}
                        placement="bottom-start"
                        openDelay={100}
                      >
                        {t('apps.competentie.form.gender.title')}
                      </Tooltip>
                    </FormLabel>
                    <Select id="gender" {...register('gender')}>
                      <option selected hidden disabled value="">
                        {t('apps.competentie.form.gender.placeholder')}
                      </option>
                      {(
                        t('apps.competentie.form.gender.options', {
                          returnObjects: true,
                        }) as ValueLabel[]
                      ).map((el, idx) => (
                        <option value={idx} key={idx}>
                          {el.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl isRequired>
                    <HStack justifyContent="space-between">
                      <FormLabel>
                        <Tooltip
                          label={t('apps.competentie.form.competency.tooltip')}
                          fontSize="md"
                          arrowPadding={4}
                          hasArrow={true}
                          placement="bottom-start"
                          openDelay={100}
                        >
                          {t('apps.competentie.form.competency.title')}
                        </Tooltip>
                      </FormLabel>
                      {willUseDefinitions? <Badge colorScheme='green'>{t('apps.competentie.form.useDefinitions.yes')}</Badge>: <Badge >{t('apps.competentie.form.useDefinitions.no')}</Badge>}

                      {selectOther && (
                        <Button size="xs" onClick={resetCompetence}>
                          {t('buttons.reset')}
                        </Button>
                      )}
                    </HStack>
                    {!selectOther ? (
                      <Select
                        id="competence"
                        {...register('competence', {
                          required: 'This is required',
                          onChange: onCompetenceChange,
                        })}
                      >
                        <option selected hidden disabled value="">
                          {t('apps.competentie.form.competency.placeholder')}
                        </option>
                        {(
                          t('apps.competentie.form.competency.options', {
                            returnObjects: true,
                          }) as ValueLabel[]
                        )
                          .map((el, idx) => ({ ...el, originalIndex: idx })) // Include original index
                          .sort((a, b) => {
                            const priorityValues = [
                              'Eigen competentie formuleren...',
                              'Formulate own competency...',
                              'Formuler votre propre compétence...',
                            ];
                            const isAPriority = priorityValues.includes(
                              a.label
                            );
                            const isBPriority = priorityValues.includes(
                              b.label
                            );

                            if (isAPriority && !isBPriority) {
                              return -1; // a comes first
                            } else if (!isAPriority && isBPriority) {
                              return 1; // b comes first
                            }
                            return a.label.localeCompare(b.label); // Alphabetical sort for others
                          }) // Custom sorting function
                          .map((el) => (
                            <option
                              value={el.originalIndex}
                              key={el.originalIndex}
                            >
                              {el.label}
                            </option>
                          ))}
                      </Select>
                    ) : (
                      <Input
                        placeholder={t(
                          'apps.competentie.form.competency.placeholder'
                        )}
                        id="competence"
                        size="md"
                        {...register('competence', {
                          required: 'This is required',
                        })}
                        ref={setRef}
                      />
                    )}
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel>
                      <Tooltip
                        label={t('apps.competentie.form.level.tooltip')}
                        fontSize="md"
                        arrowPadding={4}
                        hasArrow={true}
                        placement="bottom-start"
                        openDelay={100}
                      >
                        {t('apps.competentie.form.level.title')}
                      </Tooltip>
                    </FormLabel>
                    <Select
                      id="level"
                      {...register('level', {
                        required: 'This is required',
                      })}
                    >
                      <option selected hidden disabled value="">
                        {t('apps.competentie.form.level.placeholder')}
                      </option>
                      {(
                        t('apps.competentie.form.level.options', {
                          returnObjects: true,
                        }) as ValueLabel[]
                      ).map((el, idx) => (
                        <option value={idx} key={idx}>
                          {el.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl>
                    <HStack justifyContent="space-between">
                      <FormLabel>
                        <Tooltip
                          label={t('apps.competentie.form.psytechTest.tooltip')}
                          fontSize="md"
                          arrowPadding={4}
                          hasArrow={true}
                          placement="bottom-start"
                          openDelay={100}
                        >
                          {t('apps.competentie.form.psytechTest.title')}
                        </Tooltip>
                      </FormLabel>
                      <Button
                        size="xs"
                        onClick={() => openEditModal('psytechTest')}
                      >
                        {t('buttons.edit')}
                      </Button>
                    </HStack>
                    <AutoResizeTextarea
                      placeholder={t(
                        'apps.competentie.form.psytechTest.placeholder'
                      )}
                      id="psytechTest"
                      {...register('psytechTest')}
                      maxRows={5}
                      style={{ overflowY: 'scroll' }}
                    />
                  </FormControl>

                  <FormControl>
                    <HStack justifyContent="space-between">
                      <FormLabel>
                        <Tooltip
                          label={t('apps.competentie.form.simTest.tooltip')}
                          fontSize="md"
                          arrowPadding={4}
                          hasArrow={true}
                          placement="bottom-start"
                          openDelay={100}
                        >
                          {t('apps.competentie.form.simTest.title')}
                        </Tooltip>
                      </FormLabel>
                      <Button
                        size="xs"
                        onClick={() => openEditModal('simTest')}
                      >
                        {t('buttons.edit')}
                      </Button>
                    </HStack>
                    <AutoResizeTextarea
                      placeholder={t(
                        'apps.competentie.form.simTest.placeholder'
                      )}
                      id="simTest"
                      {...register('simTest')}
                      maxRows={5}
                      style={{ overflowY: 'scroll' }}
                    />
                  </FormControl>

                  <FormControl>
                    <HStack justifyContent="space-between">
                      <FormLabel>
                        <Tooltip
                          label={t('apps.competentie.form.otherNotes.tooltip')}
                          fontSize="md"
                          arrowPadding={4}
                          hasArrow={true}
                          placement="bottom-start"
                          openDelay={100}
                        >
                          {t('apps.competentie.form.otherNotes.title')}
                        </Tooltip>
                      </FormLabel>
                      <Button
                        size="xs"
                        onClick={() => openEditModal('otherNotes')}
                      >
                        {t('buttons.edit')}
                      </Button>
                    </HStack>
                    <AutoResizeTextarea
                      placeholder={t(
                        'apps.competentie.form.otherNotes.placeholder'
                      )}
                      id="otherNotes"
                      {...register('otherNotes')}
                      maxRows={5}
                      style={{ overflowY: 'scroll' }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>
                      <Tooltip
                        label={t('apps.competentie.form.tone.tooltip')}
                        fontSize="md"
                        arrowPadding={4}
                        hasArrow={true}
                        placement="bottom-start"
                        openDelay={100}
                      >
                        {t('apps.competentie.form.tone.title')}
                      </Tooltip>
                    </FormLabel>
                    <Select id="tone" {...register('tone')}>
                      <option selected hidden disabled value="">
                        {t('apps.competentie.form.tone.placeholder')}
                      </option>
                      {(
                        t('apps.competentie.form.tone.options', {
                          returnObjects: true,
                        }) as ValueLabel[]
                      ).map((el, idx) => (
                        <option value={idx} key={idx}>
                          {el.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormLabel>
                      <Tooltip
                        label={t('apps.competentie.form.responseType.tooltip')}
                        fontSize="md"
                        arrowPadding={4}
                        hasArrow={true}
                        placement="bottom-start"
                        openDelay={100}
                      >
                        {t('apps.competentie.form.responseType.title')}
                      </Tooltip>
                    </FormLabel>
                    <Select id="responseType" {...register('responseType')}>
                      <option selected hidden disabled value="">
                        {t('apps.competentie.form.responseType.placeholder')}
                      </option>
                      {(
                        t('apps.competentie.form.responseType.options', {
                          returnObjects: true,
                        }) as ValueLabel[]
                      ).map((el, idx) => (
                        <option value={idx} key={idx}>
                          {el.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </DrawerBody>

              <DrawerFooter>
                <Button
                  background="#e19d2e44"
                  color="#e19d2e"
                  isLoading={isSubmitting}
                  type="submit"
                >
                  {t('apps.competentie.form.submit')}
                </Button>
              </DrawerFooter>
            </form>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <Flex
        width={'100vw'}
        height={'100vh'}
        alignContent={'center'}
        justifyContent={'center'}
      >
        <ThemeSelector />
        <LanguageSelector />

        <Center w="100%">
          {loading === false && data === '' ? (
            <VStack>
              <Image src={butterfly} alt="Logo" />
              <Text fontSize="x-large" fontWeight="semibold" color={text}>
                Assess-Mate
              </Text>
            </VStack>
          ) : (
            loading === true &&
            data === '' && (
              <HashLoader
                color={'#E19D2E'}
                loading={loading}
                cssOverride={override}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )
          )}

          {data !== '' && (
            <VStack
              w="100%"
              h="100%"
              maxWidth={'container.lg'}
              justifyContent="space-between"
              paddingTop={24}
              paddingBottom={12}
            >
              <Container
                maxW="container.xl"
                overflowY="auto"
                height="4xl"
                css={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#e2e8f066',
                    borderRadius: '24px',
                  },
                }}
              >
                <HStack
                  alignItems="start"
                  spacing={16}
                  paddingX={{ base: 0, lg: 24 }}
                  position="relative"
                >
                  <Image src={butterfly} height={8} marginLeft={-24} />
                  <Text
                    maxW={'container.md'}
                    width={'container.md'}
                    marginX={'auto'}
                  >
                    <Markdown
                      children={data}
                      className={'react-markdown-class-ref'}
                    />
                  </Text>
                </HStack>
              </Container>

              <AdjustmentForm
                data={data}
                disabled={isSubmitting}
                setData={setData}
                setRequestId={setRequestId}
              />
            </VStack>
          )}
        </Center>
      </Flex>

      <Modal isOpen={isEditOpen} onClose={onEditClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t(`apps.competentie.form.${editValue}.title`)}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AutoResizeTextarea
              placeholder={t(`apps.competentie.form.${editValue}.placeholder`)}
              id={editValue}
              {...register(editValue, {
                required: 'This is required',
              })}
              maxRows={40}
              style={{ overflowY: 'scroll' }}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              background="#e19d2e44"
              color="#e19d2e"
              colorScheme="blue"
              onClick={onEditClose}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
