import { useTranslation } from 'react-i18next';
import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';

const languages = [
  { value: 'en', label: '🇬🇧', sublabel: 'English' },
  { value: 'nl', label: '🇳🇱', sublabel: 'Nederlandse' },
  { value: 'fr', label: '🇫🇷', sublabel: 'Français' },
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="outline"
        colorScheme="gray"
        style={{ position: 'absolute', right: 32, top: 32 }}
      >
        {languages.find((e) => e.value === i18n.language)?.label}
      </MenuButton>
      <MenuList>
        {languages.map((language) => (
          <MenuItem
            value={language.value}
            fontSize="medium"
            onClick={() => i18n.changeLanguage(language.value)}
          >
            <HStack spacing={2}>
              <Text>{language.label}</Text>
              <Text fontWeight="medium">{language.sublabel}</Text>
            </HStack>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
export default LanguageSelector;
