import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Home from './screens/Home';
import Welcome from './screens/Welcome';
import ProtectedRoute from './components/ProtectedRoute';
import Competency from './screens/functions/Competency';
import Motivation from './screens/functions/Motivation';
import FluviusApp from './screens/functions/Fluvius';
import SmulderApp from './screens/functions/Smulders';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Home />
      </ProtectedRoute>
    ),
  },
  {
    path: '/Welcome',
    element: <Welcome />,
  },
  {
    path: '/Competentieargumenteren',
    element: (
      <ProtectedRoute>
        <Competency />
      </ProtectedRoute>
    ),
  },
  {
    path: '/Motivatieargumenteren',
    element: (
      <ProtectedRoute>
        <Motivation />
      </ProtectedRoute>
    ),
  },
  {
    path: '/Fluvius',
    element: (
      <ProtectedRoute>
        <FluviusApp />
      </ProtectedRoute>
    ),
  },
  {
    path: '/Smulders',
    element: (
      <ProtectedRoute>
        <SmulderApp />
      </ProtectedRoute>
    ),
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <div className="App">
        <header className="App-header">
          <RouterProvider router={router} />
        </header>
      </div>
    </ChakraProvider>
  </React.StrictMode>
);
