import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthInfo, ClientPrincipal, AuthClaim } from '../types/authTypes';
import { pseudoAuthInfo } from '../assets/auth/PseudoAuthinfo';

// Component for storing, retrieving and checking auth information.
// Logs out users that are not logged in with the correct tenant
export default function useAuthInfo() {
    const [authInfo, setAuthInfo] = React.useState<AuthInfo | null>(null);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (window.location.hostname === 'localhost') {
            setAuthInfo(pseudoAuthInfo);
        } else {
            fetch('/.auth/me')
                .then((response) => response.json())
                .then((data: AuthInfo) => {
                    if (!data || !data.clientPrincipal) {
                        setAuthInfo({ clientPrincipal: null });
                        return; // Exit if no data or clientPrincipal
                    }

                    if (!data.clientPrincipal.claims) {
                        console.log('clientPrincipal has no claims', data);
                        setAuthInfo({ clientPrincipal: null });
                        window.location.href = import.meta.env.VITE_HOMEPAGE + '/.auth/logout';
                        return;
                    }

                    if (isClientPrincipalInAllowedTenants(data)) {
                        setAuthInfo(data);
                    } else {
                        console.log('unallowed tenant', data);
                        setAuthInfo({ clientPrincipal: null });
                        window.location.href = import.meta.env.VITE_HOMEPAGE + '/.auth/logout';
                    }
                })
                .catch((error) => {
                    console.error('Error fetching auth data:', error);
                    navigate(`/welcome`);
                });
        }
    }, []);

    function isClientPrincipalInAllowedTenants(authInfo: AuthInfo | null) {
        const allowedTenantIds = [
            // Wonka, ITZU, CPM, IMABENELUX (Test)
            '0b316ab4-c894-4abb-874a-3bbdc627273f', 
            '15eb57d7-4484-4134-a279-8bfa501b9c09', 
            'e39d260e-99c0-4473-9c5d-c3cad80d7981',
            '1fdd79ec-a1b5-4b47-859e-21ff3506544e'
        ];

        if (!authInfo || !authInfo.clientPrincipal?.claims) {
            return false;
        }

        const tenantIdKeyName = "http://schemas.microsoft.com/identity/claims/tenantid";
        const claims = authInfo.clientPrincipal.claims;
        const tenantIdClaim = claims.find(obj => obj.typ === tenantIdKeyName);

        if (tenantIdClaim) {
            return allowedTenantIds.includes(tenantIdClaim.val);
        } else {
            // If no tenant ID claim is found, return false or handle as needed
            return false;
        }
    }

    React.useEffect(()=>{console.log(authInfo)}, [authInfo])


    return { authInfo };
}