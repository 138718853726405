import { Button, useColorMode } from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';

const ThemeSelector = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Button
      variant="outline"
      colorScheme="gray"
      style={{ position: 'absolute', right: 96, top: 32 }}
      onClick={toggleColorMode}
    >
      {colorMode === 'light' ? <SunIcon /> : <MoonIcon />}
    </Button>
  );
};
export default ThemeSelector;
