import React from 'react';
import { useTranslation } from 'react-i18next';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import {
  Button,
  Container,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { ArrowUpIcon } from '@chakra-ui/icons';
import useAuthInfo from '../hooks/useAuthInfo';

interface AdjustmentFormProps {
  data: string;
  disabled: boolean;
  setData: (data: string) => void;
  setRequestId: (requestId: string) => void;
}

const AdjustmentForm: React.FC<AdjustmentFormProps> = (props) => {
  const { t, i18n } = useTranslation();
  const {authInfo} = useAuthInfo()

  const [loading, setLoading] = React.useState(false);
  const [adjustments, setAdjustments] = React.useState('');
  const adjust = async (summary: string, adjustments: string) => {
    let req_body = {
      summary: summary,
      notes: adjustments,
      language: i18n.language,
      auth_info: authInfo,
    };
    setLoading(true);
    try {
      let buffer = '';
      await fetchEventSource(import.meta.env.VITE_API_URL + '/adjust', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
        },
        openWhenHidden: true,
        body: JSON.stringify(req_body), // body data type must match "Content-Type" header,
        onmessage({ event, data }) {
          if (event === 'pl_request_id') {
            props.setRequestId(data);
            setLoading(false);
          } else if (event === 'data') {
            buffer += data;
            props.setData(buffer);
          }
        },
      });
    } catch (err) {
      console.log(err);
      setLoading(false)
    }
  };

  return (
    <Container  maxW={'container.md'} marginX={'auto'}>
      <VStack align="start">
        <Text fontWeight="medium" fontSize="lg">
          {t('chatbox.adjust_title')}
        </Text>
        <InputGroup size="lg">
          <Textarea
            maxHeight="2.5rem"
            minHeight="2.5rem"
            pr="4.5rem"
            placeholder={t('chatbox.placeholder')}
            rounded={20}
            onChange={(e) => setAdjustments(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault(); // Prevents the default action (newline)
                adjust(props.data, adjustments); // Calls the adjust function
              }
            }}
          />
          <InputRightElement width="4.5rem" h={"2.5rem"}>
            <Button
              h="1.75rem"
              size="md"
              rounded={20}
              background="#e19d2e44"
              color="#e19d2e"
              isLoading={props.disabled || loading}
              onClick={() => adjust(props.data, adjustments)}
            >
              <ArrowUpIcon />
            </Button>
          </InputRightElement>
        </InputGroup>
        <Text alignSelf="center" fontSize="sm" color="gray.500">
          {t('chatbox.adjust_description')}
        </Text>
      </VStack>
    </Container>
  );
};

export default AdjustmentForm;
