import butterfly from '../assets/butterfly.png';
import orderFill from '../assets/icons/Order_fill.png';
import editFill from '../assets/icons/Edit_fill.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../components/LanguageSelector';
import {
  Box,
  Card,
  CardBody,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import ThemeSelector from '../components/ThemeSelector';

export default function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const text = useColorModeValue('gray.600', 'gray.400');
  const hoverBg = useColorModeValue('gray.50', 'gray.600');

  // Warm-up the backend and navigate to link
  const warmpUpAndNavigate = (link: string) => {
    fetch(import.meta.env.VITE_API_URL + '/')
    console.log('Warm-up backend at', import.meta.env.VITE_API_URL + '/')
    navigate(link);
  }

  return (
    <Flex
      width={'100vw'}
      height={'100vh'}
      alignContent={'center'}
      justifyContent={'center'}
    >
      <ThemeSelector />
      <LanguageSelector />

      <Center w="100%">
        <VStack spacing={16} w="100%">
          <VStack>
            <Image src={butterfly} alt="Logo" />
            <Text fontSize="x-large" fontWeight="semibold" color={text}>
              Assess-Mate
            </Text>
          </VStack>

          <Container maxW="3xl">
            <VStack alignItems="start" spacing={2}>
              <Text fontWeight="medium" fontSize="large" color={text}>
                {t('home.features')}
              </Text>
              <Card
                w="100%"
                cursor="pointer"
                _hover={{ background: hoverBg }}
                shadow="xs"
                onClick={() => warmpUpAndNavigate(`/Competentieargumenteren`)}
              >
                <CardBody>
                  <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
                    <Image src={editFill} />

                    <Box>
                      <Heading size="sm" color="#e19d2e">
                        {t('apps.competentie.name')}
                      </Heading>
                      <Text color={text} fontWeight="medium">
                        {t('apps.competentie.description')}
                      </Text>
                    </Box>
                  </Flex>
                </CardBody>
              </Card>
              <Card
                w="100%"
                cursor="pointer"
                _hover={{ background: hoverBg }}
                shadow="xs"
                onClick={() => warmpUpAndNavigate(`/Motivatieargumenteren`)}
              >
                <CardBody>
                  <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
                    <Image src={orderFill} />

                    <Box>
                      <Heading size="sm" color="#e19d2e">
                        {t('apps.motivatie.name')}
                      </Heading>
                      <Text color={text} fontWeight="medium">
                        {t('apps.motivatie.description')}
                      </Text>
                    </Box>
                  </Flex>
                </CardBody>
              </Card>
              <Card
                w="100%"
                cursor="pointer"
                _hover={{ background: hoverBg }}
                shadow="xs"
                onClick={() => navigate(`/Fluvius`)}
              >
                <CardBody>
                  <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
                    <Image src={editFill} />

                    <Box>
                      <Heading size="sm" color="#e19d2e">
                        {t('apps.fluvius.name')}
                      </Heading>
                      <Text color={text} fontWeight="medium">
                        {t('apps.fluvius.description')}
                      </Text>
                    </Box>
                  </Flex>
                </CardBody>
              </Card>
              <Card
                w="100%"
                cursor="pointer"
                _hover={{ background: hoverBg }}
                shadow="xs"
                onClick={() => navigate(`/Smulders`)}
              >
                <CardBody>
                  <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
                    <Image src={editFill} />

                    <Box>
                      <Heading size="sm" color="#e19d2e">
                        {t('apps.smulders.name')}
                      </Heading>
                      <Text color={text} fontWeight="medium">
                        {t('apps.smulders.description')}
                      </Text>
                    </Box>
                  </Flex>
                </CardBody>
              </Card>
            </VStack>
          </Container>
        </VStack>
      </Center>
    </Flex>
  );
}
