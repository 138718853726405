import React from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, Button, useColorModeValue } from '@chakra-ui/react';
import { FaCopy, FaThumbsDown, FaThumbsUp } from 'react-icons/fa6';
import { useToast } from '@chakra-ui/react';

interface FeedbackContainerProps {
  inline?: boolean;
  hideFeedback?: boolean;
  requestId: string;
  data: string;
  markdownAsHtml: string;
  margin?: number;
}

const FeedbackContainer: React.FC<FeedbackContainerProps> = ({
  inline,
  hideFeedback,
  requestId,
  margin,
  data,
  markdownAsHtml,
}) => {
  const { t } = useTranslation();
  const copy = useColorModeValue('gray.600', 'gray.400');

  const toast = useToast();
  const giveFeedback = async (thumbsUp: boolean) => {
    await fetch(`${import.meta.env.VITE_API_URL}/feedback`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        request_id: String(requestId),
        positive: Boolean(thumbsUp),
      }),
    })
      .then(() => {
        toast({
          description: thumbsUp ? t('feedback.thanks') : t('feedback.helpful'),
          status: 'success',
          position: 'top-right',
          duration: 4000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error(error);
        toast({
          description: t('feedback.error'),
          status: 'error',
          position: 'top-right',
          duration: 4000,
          isClosable: true,
        });
      });
  };

  const copyToClipboardAsRichText = () => {
    // Create a new div element
    const element = document.createElement('div');

    // Set the innerHTML to the markdown HTML content
    element.innerHTML = markdownAsHtml;
    cleanUpStyles(element);

    // Set contenteditable attribute so the content can be selected
    element.setAttribute('contenteditable', 'true');

    // Add the element to the document so it can be focused and selected
    document.body.appendChild(element);

    // Select the content
    const range = document.createRange();
    range.selectNodeContents(element);
    const selection = window.getSelection();

    if (selection) {
      // Check if selection is not null
      selection.removeAllRanges();
      selection.addRange(range);
      // Copy the selection to clipboard
      try {
        // Use execCommand for HTML content
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text command was ' + msg);
      } catch (err) {
        console.error('Failed to copy', err);
      }

      // Clean up
      selection.removeAllRanges();
    } else {
      console.error('Could not retrieve selection object. Copy failed.');
    }

    document.body.removeChild(element);
  };

  // Function to remove unwanted styles from an element
  const cleanUpStyles = (element: HTMLElement) => {
    element.removeAttribute('style'); // Remove inline styles

    // Remove border properties
    element.style.borderWidth = '0';
    element.style.borderStyle = 'none';
    element.style.borderColor = 'transparent';

    // Remove background color
    element.style.backgroundColor = 'transparent';

    // Remove any class attributes
    const classes = element.getAttribute('class');
    if (classes) {
      element.removeAttribute('class');
    }

    // Recursively clean up child elements
    element.childNodes.forEach((child) => {
      if (child.nodeType === Node.ELEMENT_NODE) {
        cleanUpStyles(child as HTMLElement);
      }
    });
  };

  const copyData = () => {
    copyToClipboardAsRichText();
    toast({
      description: t('feedback.copied'),
      status: 'success',
      position: 'top-right',
      duration: 4000,
      isClosable: true,
    });
  };

  return (
    <HStack position={inline ? 'initial' : 'absolute'} bottom={-8} right={0} marginRight={{ base: 0, lg: (margin || 24) }}>
      <Button variant="ghost" size="sm" color={copy} onClick={copyData}>
        <FaCopy />
      </Button>
      {
        !hideFeedback && (
          <>
            <Button
              variant="ghost"
              size="sm"
              color="green.300"
              onClick={() => giveFeedback(true)}
            >
              <FaThumbsUp />
            </Button>
            <Button
              variant="ghost"
              size="sm"
              color="red.300"
              onClick={() => giveFeedback(false)}
            >
              <FaThumbsDown />
            </Button>
          </>
        )
      }
    </HStack>
  );
};

export default FeedbackContainer;
